import React from 'react';
import clsx from 'clsx';
import PropType from 'prop-types';
import MuiCheckbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    width: 55,
    height: 45,
    paddingTop: 25,
  },
  checkbox: {
    fontSize: '1.5rem',
  },
  isChecked: {
    color: theme.palette.primary.main,
  },
}));

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    '&:checked': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  checked: {
    fontSize: '1.5rem',
  },
}))((props) => <MuiCheckbox color="default" {...props} />);

const Checkbox = ({ checked, onChange, ...props }) => {
  const classes = useStyles();
  const handleChange = (e) => {
    onChange(e.target.checked, e.target.checked);
  };

  return (
    <CustomCheckbox
      className={clsx(classes.checkbox, { [classes.isChecked]: checked })}
      icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
      checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
      onChange={handleChange}
      checked={checked}
      {...props}
    />
  );
};

Checkbox.propTypes = {
  checked: PropType.bool.isRequired,
  onChange: PropType.func.isRequired,
};

export default Checkbox;
