import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select, FormControl } from '@material-ui/core';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

const SelectField = ({ value, onChange, placeholder, options, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        onChange={(e) => {
          onChange(e.target.value, true);
        }}
        displayEmpty
        data-testid="select-field"
        {...props}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>

        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
};

SelectField.defaultProps = {
  value: '',
  required: false,
};

export default SelectField;
