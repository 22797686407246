import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  insuredUnauthorized,
  fetchStatus,
  equalCnpjInsuredAndPolicyholder,
} from 'src/shared/constants';
import { getProductKeyByPathname } from 'src/shared/helpers';
import { quizOperations } from '../redux';
import {
  getActiveQuestion,
  getInsured,
  getActiveProperty,
  getQuizPropertyCnpjLicitante,
  getInsuredError,
  getBrokerOptions,
  getBrokerIsLoading,
  getAllowBrokerSearch,
  getBrokerAppointmentData,
} from '../redux/quizSelectors';
import { setInsuredError, setQuizProperty, cleanInsured } from '../redux/quizActions';

export default () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const { quoteId } = useParams();

  const activeQuestion = useSelector(getActiveQuestion);
  const activeProperty = useSelector(getActiveProperty);

  const insured = useSelector(getInsured);
  const { query } = useSelector(({ router }) => router.location);

  const [openModalError, setOpenModalError] = useState(false);

  const insuredError = useSelector(getInsuredError);
  const cnpjLicitante = useSelector(getQuizPropertyCnpjLicitante);
  const brokerOptions = useSelector(getBrokerOptions);
  const brokerIsLoading = useSelector(getBrokerIsLoading);
  const allowBrokerSearch = useSelector(getAllowBrokerSearch);
  const brokerAppointmentData = useSelector(getBrokerAppointmentData);
  const productKey = getProductKeyByPathname(location.pathname);

  const [additionalProps, setAdditionalProps] = useState({});
  const [brokerAdditionalProps, setBrokerAdditionalProps] = useState({});
  const [insuredErrorMessage, setInsuredErrorMessage] = useState(
    'Ocorreu um erro ao validar o CNPJ. Por favor, tente mais tarde ou entre em contato em um dos canais de comunicação.'
  );
  const ackInsuredError = () => {
    setOpenModalError(false);
    dispatch(cleanInsured());
  };
  const insuredForbidden = insured.fetchStatus === fetchStatus.rejected;

  const handleSearchBrokerOptions = useCallback((filter) => {
    const payload = {
      brokerName: filter,
      pageNumber: 1,
      pageSize: 15,
      productKey
    }
    dispatch(quizOperations.searchBrokerOptions(payload));
  }, [productKey, dispatch]);

  useEffect(() => {
    if (cnpjLicitante !== undefined && cnpjLicitante.valid) {
      dispatch(quizOperations.verifyBrokerAppointment({ productKey, cnpjLicitante: cnpjLicitante.value }));
    }
  }, [productKey, cnpjLicitante, dispatch])

  useEffect(() => {
    setBrokerAdditionalProps({
      disabled: !allowBrokerSearch,
    });

    const value = {
      name: brokerAppointmentData.brokerName,
      documentNumber: brokerAppointmentData.brokerDocument
    };
    dispatch(setQuizProperty({ name: 'BROKER_NAME', value, valid: true }));
    dispatch(setQuizProperty({ name: 'BROKER_DOCUMENT', value: brokerAppointmentData.brokerDocument, valid: true }));
  }, [allowBrokerSearch, brokerAppointmentData, dispatch])

  useEffect(() => {
    if (insured.isCnpjAllowed === false) {
      push(`${location.pathname}${insuredUnauthorized}`);
    }
  }, [insured, push, location.pathname]);

  useEffect(() => {
    setAdditionalProps({});
    if (
      insured.fetchStatus === fetchStatus.fulfilled &&
      activeProperty?.value === insured.documentNumber
    ) {
      return;
    }
    if (insured.fetchStatus === fetchStatus.rejected && activeProperty?.valid) {
      setOpenModalError(true);
      return;
    }
    if (insured.fetchStatus === fetchStatus.fulfilled && !activeProperty?.valid) {
      dispatch(cleanInsured());
      return;
    }
    if (
      activeQuestion.propertyName === 'CNPJ_SEGURADO' &&
      cnpjLicitante !== undefined &&
      activeProperty?.value === cnpjLicitante?.value &&
      !insuredError?.hasError
    ) {
      setInsuredErrorMessage(equalCnpjInsuredAndPolicyholder);
      dispatch(setInsuredError({ hasError: true, message: equalCnpjInsuredAndPolicyholder }));
      setAdditionalProps({ inputIsInvalid: true, errorMessage: equalCnpjInsuredAndPolicyholder });
      return;
    }
    if (
      activeQuestion.propertyName === 'CNPJ_SEGURADO' &&
      cnpjLicitante !== undefined &&
      activeProperty?.value !== cnpjLicitante.value &&
      insuredError?.hasError
    ) {
      setAdditionalProps({});
      dispatch(setInsuredError({}));
    }
    if (activeQuestion.propertyName === 'BROKER_NAME') {
      setAdditionalProps({
        searchOptions: handleSearchBrokerOptions,
        autocompleteOptions: brokerOptions,
        isLoading: brokerIsLoading,
        optionLabel: (option) => (`${option.name} - ${option.documentNumber}`),
        ...brokerAdditionalProps,
      });
    }
  }, [
    dispatch,
    query.insuredSector,
    activeQuestion.propertyName,
    insured,
    activeProperty,
    quoteId,
    cnpjLicitante,
    insuredError,
    setInsuredErrorMessage,
    setAdditionalProps,
    handleSearchBrokerOptions,
    brokerOptions,
    brokerIsLoading,
    brokerAdditionalProps,
  ]);

  return {
    insured,
    insuredForbidden,
    openModalError,
    insuredError,
    ackInsuredError,
    insuredErrorMessage,
    additionalProps,
  };
};
