import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  routes,
  productNames,
  productComplement,
} from 'src/shared/constants';
import {
  Button,
  Grid,
  makeStyles,
  MobileStepper,
  Typography,
} from '@material-ui/core';
import { Declined } from 'src/shared/assets/svg';
import { useQuizSize, useGoogleAnalytics } from 'src/shared/hooks';
import { getProductKey } from 'src/features/quiz/redux/quizSelectors';
import { getCreateQuoteRejected } from '../redux/quoteSelector';

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: 40,
  },
  product: {
    textAlign: 'center',
    fontSize: '0.7rem',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  productName: {
    marginTop: 5,
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 22,
    color: theme.palette.text.darkBlue,
    textAlign: 'center',
    marginTop: 35,
    padding: '0 16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '5vw',
    },
  },
  image: {
    width: 150,
    marginTop: 25,
  },
  alignCenter: {
    textAlign: 'center',
  },
  btnContainer: {
    marginTop: 10,
    textAlign: 'center',
  },
  button: {
    width: 200,
    marginTop: 20,
  },
  textInfo: {
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    marginTop: 40,
    marginBottom: 10,
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  rootStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    margin: '0 15px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const QuoteUnderEvaluation = () => {
  const classes = useStyles();
  const size = useQuizSize();
  const productKey = useSelector(getProductKey);

  const { push } = useHistory();
  const { event } = useGoogleAnalytics();
  const wasQuoteRejected = useSelector(getCreateQuoteRejected);

  useEffect(() => {
    if (wasQuoteRejected) {
      event('Quote', 'QuoteUnderEvaluation', `${productNames[productKey]}`);
    }
  }, [wasQuoteRejected, event, productKey]);

  const returnToHome = useCallback(() => {
    push(routes.home.path);
  }, [push]);

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.stepper}>
        <MobileStepper
          steps={size}
          variant="dots"
          position="static"
          className={classes.root}
          classes={{ dot: classes.dot, root: classes.rootStep }}
          data-testid="finished-stepper"
        />
      </Grid>

      <Grid item xs={12} className={classes.productName}>
        <Typography data-testid="title" className={clsx(classes.product, classes.productName)}>
          {productNames[productKey]}
        </Typography>
      </Grid>
      {productComplement[productKey] && (
        <Grid item xs={12}>
          <Typography data-testid="productName" className={classes.product}>
            {productComplement[productKey]}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography className={classes.title}>Ops! Não foi possível prosseguir com a sua cotação...</Typography>
      </Grid>
      <Grid item xs={12} className={classes.alignCenter}>
        <img src={Declined} alt="Under Evaluation" className={classes.image} />
      </Grid>

      <Grid item xs={10} md={4} lg={4} xl={3} className={classes.alignCenter}>
        <Typography className={classes.textInfo}>
          Infelizmente, após análise dos dados informados, não será possível seguir com a sua aprovação. Esperamos poder te atender em uma nova demanda!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center" className={classes.btnContainer}>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={returnToHome}
              className={classes.button}
            >
              Sair
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

QuoteUnderEvaluation.propTypes = {};

export default QuoteUnderEvaluation;
