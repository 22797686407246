import { isString, indexOf } from 'lodash';
import { routeProductKey, productKeys } from '../constants';

export default (pathname) => {
  if (!isString(pathname) || pathname[0] !== '/') return null;
  const path = pathname.split('/')[1];
  const productKey = routeProductKey[`/${path}`];
  if (isString(productKey) && indexOf(productKeys, productKey) !== -1) return productKey;
  return null;
};
