import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export const CustomLineTitle = ({ title, fontSize }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxTitle}>
      <Typography className={classes.title} style={{ fontSize: fontSize || 18 }}>
        {title}
      </Typography>
      <hr className={classes.horizontalLine} />
    </Box>
  );
};
