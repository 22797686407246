import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { loading } from 'src/shared/assets/config';
import { useSpring, animated } from 'react-spring';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'rgb(243 243 243 / 80%)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  loading: {
    width: 50,
    maxWidth: '100%',
    animation: '$spin 2.5s linear infinite',
    [theme.breakpoints.down('xs')]: {
      width: 40,
    },
  },
  svgContainer: {
    width: 50,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    padding: '20px 25px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
    },
  },
  text: {
    textAlign: 'center',
    margin: '1rem 0',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
  },
  cls1: {
    fill: theme.palette.primary.main,
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(-360deg)',
    },
  },
}));

const LoaderInformative = ({ loadingText }) => {
  const classes = useStyles();
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.div data-testid="loaderInformative" style={props} className={classes.root}>
      <div className={classes.content}>
        <div className={classes.svgContainer}>
          <Lottie
            options={{
              animationData: loading,
            }}
          />
        </div>
        <Typography className={classes.text}>{loadingText}</Typography>
      </div>
    </animated.div>
  );
};

LoaderInformative.propTypes = {
  loadingText: PropTypes.string,
};

LoaderInformative.defaultProps = {
  loadingText: 'Aguarde um instante, estamos analisando sua solicitação...',
};

export default LoaderInformative;
