import React from 'react';
import { useDispatch, batch } from 'react-redux';
import { routes, baseNumeroEditalContent } from 'src/shared/constants';
import { MessageBox } from 'src/shared/components';
import { Typography, makeStyles } from '@material-ui/core';
import { useQuiz, useProductKey, useQuote, useInsured } from '../hooks';
import {
  setQuestionContentPreviewText,
  setQuestionPlaceholder,
  setQuestionTitle,
  setQuestionBypass,
  setQuestionRequired,
} from '../redux/quizActions';
import CustomQuiz from './CustomQuiz';

const DEFAULT_VALUES = [
  {
    propertyName: 'OBRA_CIVIL',
    value: 'Nao',
  }
]

const useStyles = makeStyles({
  insuredName: {
    fontSize: 14,
    padding: '5px 8px',
    marginTop: -10,
    marginBottom: -21,
    backgroundColor: '#EEE',
  },
});

const QuoteQuizPage = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { productKey } = useProductKey();
  const { activeQuestion, incrementStepper, decrementStepper } = useQuiz();
  const { createQuote } = useQuote();
  const {
    insured,
    insuredForbidden,
    openModalError,
    insuredError,
    ackInsuredError,
    insuredErrorMessage,
    additionalProps,
  } = useInsured();

  const nextPage = async () => {
    incrementStepper({ actionAfterQuiz: createQuote });
  };

  const previousPage = () => {
    decrementStepper({ pageBeforQuiz: routes.home.path });
  };

  const additionalDispatches = (value) => {
    if (activeQuestion.propertyName === 'MODALIDADE_LICITACAO') {
      const propertyName = 'NUMERO_EDITAL';
      let modalidade = '';
      let placeholder = 'XXX/XXXX';
      let title = 'Qual o número e ano do documento?';

      if (value === 'Outro') {
        placeholder = 'Modalidade XXX/XXXX';
        title =
          productKey === 'garantiaBidPublico'
            ? 'Qual a modalidade e número do documento?'
            : 'Qual o nome do documento e número?';
      } else {
        modalidade = value;
        placeholder = 'XXX/XXXX';
      }

      const placeholderPayload = {
        propertyName,
        placeholder,
      };
      const contentPayload = {
        propertyName,
        content:
          value !== 'Outro'
            ? `${baseNumeroEditalContent} ${modalidade} número {{NUMERO_EDITAL}}.`
            : `${baseNumeroEditalContent} ${modalidade} {{NUMERO_EDITAL}}.`,
      };
      const titlePayload = {
        propertyName,
        title,
      };

      batch(() => {
        dispatch(setQuestionPlaceholder(placeholderPayload));
        dispatch(setQuestionContentPreviewText(contentPayload));
        dispatch(setQuestionTitle(titlePayload));
      });
    }

    if (activeQuestion.propertyName === 'OBRA_CIVIL') {
      const propertyName = 'TIPO_OBRA_CIVIL';

      const bypassQuestion = value === 'Sim' ? false : true;
      const requiredQuestion = value === 'Sim' ? true : false;

      const bypassPayload = {
        propertyName,
        bypassQuestion,
      };

      const requiredPayload = {
        propertyName,
        requiredQuestion,
      };

      batch(() => {
        dispatch(setQuestionBypass(bypassPayload));
        dispatch(setQuestionRequired(requiredPayload));
      });
    }
  };

  return (
    <>
      <MessageBox
        opened={openModalError}
        type="error"
        handleClose={ackInsuredError}
        handleSecondary={ackInsuredError}
        labelSecondary="Fechar"
        title="Erro ao validar o CNPJ"
        text={insuredErrorMessage}
      />
      <CustomQuiz
        quizKey="quote"
        nextPage={nextPage}
        previousPage={previousPage}
        renderInputComplement={() => {
          if (
            activeQuestion.propertyName === 'CNPJ_SEGURADO' &&
            insured.name &&
            !insuredError?.hasError
          ) {
            return <Typography className={classes.insuredName}>{insured.name}</Typography>;
          }
          return null;
        }}
        disableNext={
          activeQuestion.propertyName === 'CNPJ_SEGURADO' &&
          (insuredForbidden || insuredError?.hasError)
        }
        additionalProps={additionalProps}
        additionalDispatches={additionalDispatches}
        defaultValues={DEFAULT_VALUES}
      />
    </>
  );
};

export default QuoteQuizPage;
