export const approved = '/aprovado';
export const underAnalysis = '/sob-analise';
export const proposalUnderAnalysis = '/sob-revisao';
export const insuredUnauthorized = '/nao-autorizado';
export const policyHolderUnauthorized = '/nao-autorizado';

export const routes = Object.freeze({
  home: {
    path: '/',
  },
  error: {
    path: '/error',
  },
  garantiaBidPublico: {
    path: '/bid',
  },
  garantiaPerformancePublico: {
    path: '/performance',
  },
  auth: {
    callback: '/callback',
    errorLogin: '/error-login',
    signout: '/signout-callback',
  },
  quote: {
    path: '/cotacao',
    params: {
      quoteId: ':quoteId',
    },
  },
  proposal: {
    path: '/proposta',
    params: {
      proposalId: ':proposalId',
    },
  },
  signup: {
    path: '/cadastro',
  },
  hyperlink: {
    quoteReady: '/hyperlink/quote-ready/:id',
    alreadyApproved: '/hyperlink/already-approved',
    invalid: '/hyperlink/invalid',
  },
});

export const routeProductKey = Object.freeze({
  [routes.garantiaBidPublico.path]: 'garantiaBidPublico',
  [routes.garantiaPerformancePublico.path]: 'garantiaPerformancePublico',
});

export const productKeys = Object.values(routeProductKey);

export const quizPaths = productKeys.map((productKey) => routes[productKey].path);

export const quoteApprovedPaths = productKeys.map(
  (productKey) => `${routes[productKey].path}${approved}`
);

export const quoteUnderAnalysisPaths = productKeys.map(
  (productKey) => `${routes[productKey].path}${underAnalysis}`
);

export const quotePaths = productKeys.map(
  (productKey) => `${routes[productKey].path}/cotacao/${routes.quote.params.quoteId}`
);

export const createProposalPaths = quotePaths.map((quotePath) => `${quotePath}/proposta`);

export const proposalPaths = productKeys.map(
  (productKey) => `${routes[productKey].path}/proposta/${routes.proposal.params.proposalId}`
);

export const proposalUnderAnalysisPaths = productKeys.map(
  (productKey) => `${routes[productKey].path}${proposalUnderAnalysis}`
);

export const policyPaths = productKeys.map(
  (productKey) => `${routes[productKey].path}/proposta/${routes.proposal.params.proposalId}/apolice`
);

export const insuredUnauthorizedPaths = createProposalPaths.map(
  (createProposalPath) => `${createProposalPath}${insuredUnauthorized}`
);

export const policyHolderUnauthorizedPaths = productKeys.map(
  (productKey) => `${routes[productKey].path}${policyHolderUnauthorized}`
);
