import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  boxTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  horizontalLine: {
    border: 'none',
    borderBottom: '2px solid #F26522',
    margin: 0,
    width: 60,
  },
}));
