import { fetchStatus } from 'src/shared/constants';

export const getActiveQuestion = ({ quiz }) => quiz.questions[quiz.stepperPosition] ?? {};
export const getQuestions = ({ quiz }) => quiz.questions ?? [];
export const getStepperInfo = ({ quiz }) => ({
  size: quiz.questions.length + 1,
  position: quiz.stepperPosition,
});
export const getProduct = ({ quiz }) => quiz.product;
export const getProductKey = ({ quiz }) => quiz.product.productKey;
export const getActiveProperty = ({ quiz }) => {
  if (quiz.questions?.length <= 0) return undefined;
  return quiz.properties[quiz.questions[quiz.stepperPosition]?.propertyName] ?? undefined;
};
export const getProperties = ({ quiz }) => quiz.properties;
export const getQuote = ({ quiz }) => quiz.quote;
export const getQuoteId = ({ quote }) => quote.quoteId;
export const getQuoteInsuredAmount = ({ quote }) => quote.insuredAmount;
export const getProposal = ({ quiz }) => quiz.proposal;
export const getError = ({ quiz }) => quiz.quizFetchStatus === fetchStatus.rejected;
export const getInsured = ({ quiz }) => quiz.insured;
export const getPropertyCnpjLicitante = ({ quote }) => quote.properties.CNPJ_LICITANTE;
export const getQuizPropertyCnpjLicitante = ({ quiz }) => quiz.properties.CNPJ_LICITANTE;
export const getInsuredError = ({ quiz }) => quiz.insured.error;
export const getBrokerOptions = ({ quiz }) => quiz.insured.brokerOptions;
export const getBrokerIsLoading = ({ quiz }) => quiz.insured.brokerIsLoading;
export const getAllowBrokerSearch = ({ quiz }) => quiz.insured.brokerAppointment.allowBrokerSearch;
export const getBrokerAppointmentData = ({ quiz }) => quiz.insured.brokerAppointment.brokerAppointmentData;
