import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getQuoteById } from 'src/features/quote/redux/quoteOperations';
import {
  getStepperInfo,
  getProperties,
  getActiveQuestion,
  getActiveProperty,
  getQuoteId,
  getProductKey,
} from '../redux/quizSelectors';
import { setStepperPosition } from '../redux/quizActions';

export const actionTypes = Object.freeze({
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
});

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const properties = useSelector(getProperties);
  const activeProperty = useSelector(getActiveProperty);
  const stepperInfo = useSelector(getStepperInfo);
  const activeQuestion = useSelector(getActiveQuestion);
  const { size, position } = stepperInfo;
  const quoteId = useSelector(getQuoteId);
  const productKey = useSelector(getProductKey);
  const [lastAction, setLastAction] = useState('');
  const [LastActionAfterQuiz, setLastActionAfterQuiz] = useState({ actionAfterQuiz: () => { } });
  const [LastPageBeforQuiz, setLastPageBeforQuiz] = useState({ pageBeforQuiz: '' })

  const isLastQuestion = position + 1 >= size - 1;
  const isFirstQuestion = position <= 0;

  useEffect(() => {
    if (quoteId && productKey) dispatch(getQuoteById({ quoteId, productKey }));
  }, [quoteId, productKey, dispatch]);

  const incrementStepper = async ({ actionAfterQuiz }) => {
    if (isLastQuestion) actionAfterQuiz();
    else dispatch(setStepperPosition(position + 1));
    setLastAction(actionTypes.INCREMENT);
    setLastActionAfterQuiz({ actionAfterQuiz })
  };
  const decrementStepper = ({ pageBeforQuiz }) => {
    if (isFirstQuestion) push(pageBeforQuiz);
    else dispatch(setStepperPosition(position - 1));
    setLastAction(actionTypes.DECREMENT);
    setLastPageBeforQuiz({ pageBeforQuiz })
  };

  useEffect(() => {
    if (activeQuestion.bypass) {
      lastAction === actionTypes.DECREMENT
        ? decrementStepper(LastPageBeforQuiz)
        : incrementStepper(LastActionAfterQuiz)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestion])

  return {
    activeQuestion,
    properties,
    activeProperty,
    stepperInfo,
    incrementStepper,
    decrementStepper,
  };
};
