import React, { useEffect, useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Grid, makeStyles } from '@material-ui/core';
import { Error } from 'src/shared/assets/svg';
import { routes } from 'src/shared/constants';
import { quizActions } from 'src/features/quiz/redux';
import { quoteActions } from 'src/features/quote/redux';
import { proposalActions } from 'src/features/proposal/redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '85%',
    alignItems: 'center',
  },
  productName: {
    marginTop: 5,
    textAlign: 'center',
    fontSize: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 36,
    color: theme.palette.text.darkBlue,
    textAlign: 'center',
  },
  image: {
    width: 170,
    marginTop: 15,
  },
  alignCenter: {
    textAlign: 'center',
  },
  btnContainer: {
    marginTop: 40,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  button: {
    width: '85%',
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
      fontSize: 14,
      height: 40,
    },
  },
  textInfo: {
    fontSize: 18,
    color: theme.palette.text.primary,
    marginTop: 25,
  },
}));

const ErrorPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    batch(() => {
      dispatch(quizActions.cleanErrors());
      dispatch(quoteActions.cleanErrors());
      dispatch(proposalActions.cleanErrors());
    });
  }, [dispatch]);

  const returnToHome = useCallback(() => {
    push(routes.home.path);
  }, [push]);

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} className={classes.alignCenter}>
          <img src={Error} alt="Error" className={classes.image} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>Oops!</Typography>
        </Grid>
        <Grid item xs={10} md={12} className={classes.alignCenter}>
          <Typography className={classes.textInfo}>
            Desculpe, parece que algo deu errado...
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={5} xs={11} className={classes.btnContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={returnToHome}
            className={classes.button}
          >
            Voltar à página inicial
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorPage;
