import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as quoteOperations from 'src/features/quote/redux/quoteOperations';
import { getProductKeyByPathname } from 'src/shared/helpers';
import { fetchStatus, routes } from 'src/shared/constants';

export default () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const { quoteId } = useParams();
  const productKey = getProductKeyByPathname(location.pathname);
  const quote = useSelector((state) => state.quote);

  const getStatus = quote.fetchStatus.getQuoteById;
  const hasGetError = useMemo(() => getStatus === fetchStatus.rejected, [getStatus]);

  useEffect(() => {
    if (hasGetError) push(routes.error.path);
    else {
      dispatch(quoteOperations.getQuoteById({ quoteId, productKey }));
    }
  }, [push, hasGetError, dispatch, productKey, quoteId]);

  return { quote, productKey };
};
