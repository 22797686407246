import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authManager } from 'src/features/auth/authManager';
import { loaderActions } from 'src/features/loader/redux';
import { routes } from 'src/shared/constants';

export default () => {
  const { push } = useHistory();
  const user = useSelector(({ oidc }) => oidc.user);
  const userManager = authManager.getUserManager();
  const dispatch = useDispatch();

  const redirectToLogin = () => {
    dispatch(loaderActions.start('QuoteLogin'));
    if (user == null) userManager.signinRedirect({ state: { callbackUrl: '/cotacao' } });
    else push(`${routes.quote.path}`);
  };

  useEffect(
    () => () => {
      dispatch(loaderActions.stop('QuoteLogin'));
    },
    [dispatch]
  );

  return { redirectToLogin };
};
