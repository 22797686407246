import { isEmpty, isObject } from 'lodash';

export default (properties) => {
  if (!isObject(properties) || isEmpty(properties)) return {};
  const response = {};
  Object.entries(properties).forEach(([key, value]) => {
    response[key] = value.value;
  });
  return response;
};
