import proposalService from './proposalService';

class ProposalManager {
  getProposalById = proposalService.getProposalById;

  acceptProposal = proposalService.acceptProposal;

  issuePolicy = async (params) => {
    const response = await proposalService.issuePolicy(params);
    return response.url;
  };

  getProposalDraft = async (params) =>{
    const response = await proposalService.getProposalDraft(params);
    return response;
  };
}

export default new ProposalManager();
