import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Typography, Paper, Button, makeStyles, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { QuoteDocument } from 'src/shared/assets/svg';
import { formatToBrlMoney } from 'src/shared/helpers';
import { routes, fetchStatus } from 'src/shared/constants';
import { useProposal } from '../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
  },
  question: {
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    marginTop: 10,
  },
  button: {
    width: '75%',
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  image: {
    width: 60,
    marginTop: 5,
  },
  content: {
    marginTop: 50,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: 360,
    padding: 20,
    marginBottom: 20,
  },
  receiptCard: {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  divider: {
    width: '85%',
    marginTop: 10,
  },
  highlightedInfo: {
    color: theme.palette.text.primary,
    fontFamily: '"Open Sans", sans-serif',
  },
  flexColumn: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const QuoteReceiptCard = ({ quote }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const { createProposal } = useProposal();

  const hireQuote = () => {
    if (quote.plataformaV2) {
      createProposal()
    } else {
      const proposalRoute = `${routes[quote.productKey].path}${routes.quote.path}/${quote.quoteId}/proposta`;
      push(proposalRoute);
    }
  };

  return (
    <Paper elevation={3} className={clsx(classes.card, classes.receiptCard)}>
      <div className={classes.flexColumn}>
        <img src={QuoteDocument} alt="QuoteDocument" className={classes.image} />
        <Typography className={classes.cardTitle}>
          {quote.fetchStatus.getQuoteById !== fetchStatus.fulfilled ? (
            <Skeleton animation="wave" height={20} width={150} variant="rect" />
          ) : (
            <>{quote.productName}</>
          )}
        </Typography>
        <Divider variant="middle" className={classes.divider} />
      </div>
      <div className={clsx(classes.flexColumn)}>
        <Typography variant="h6" className={classes.highlightedInfo}>
          VALOR DO SEGURO:
        </Typography>
        {quote.fetchStatus.getQuoteById !== fetchStatus.fulfilled ? (
          <Skeleton animation="wave" height={40} width={150} variant="rect" />
        ) : (
          <Typography variant="h4" className={classes.highlightedInfo}>
            {quote?.premium ? formatToBrlMoney(quote?.premium) : ''}
          </Typography>
        )}
      </div>
      <Button color="primary" variant="contained" className={classes.button} onClick={hireQuote}>
        Quero Contratar
      </Button>
    </Paper>
  );
};

QuoteReceiptCard.propTypes = {
  quote: PropTypes.object,
};

QuoteReceiptCard.defaultProps = {
  quote: {},
};

export default QuoteReceiptCard;
