import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { IcoWarning } from 'src/shared/assets/svg';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '1.2em',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '2px 1px 2px #80808029',
    },
    warning: {
        marginTop: '3em',
        padding: '1.2em',
        background: '#fbefd0 0% 0% no-repeat padding-box',
        boxShadow: '2px 1px 2px #80808029',
        borderRadius: '5px',
    },
    information: {
        marginTop: '3em',
    },
    danger: {
        padding: '1.2em',
    },
    contentContainer: {
        width: '100%',
    },
    content: {
        marginLeft: '40px',
        textAlign: 'left',
        fontSize: '14px',
        wordWrap: 'break-word',
        [theme.breakpoints.down('xs')]: { fontSize: '0.7em' },
        letterSpacing: '0.28px',
        color: '#4B3802',
        opacity: '1',
    },
    iconWarning: {
        float: 'left',
        marginTop: '25px',
    },
}));

const DescriptionPreview = ({ description, icon }) => {
    const classes = useStyles();

    if (isEmpty(description)) return null;

    switch (icon) {
        case 'WARNING':
            return (
                <div className={classes.warning}>
                    <Grid container>
                        <Grid item className={classes.contentContainer}>
                            <img src={IcoWarning} className={classes.iconWarning} alt="warning icon" />
                            <Typography className={classes.content}>
                                {description}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        case 'INFORMATION':
            return (
                <div className={classes.information}></div>
            );
        case 'DANGER':
            return (
                <div className={classes.danger}></div>
            );
        default:
            return null
    }
};

DescriptionPreview.propTypes = {
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default DescriptionPreview;
