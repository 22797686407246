import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Checkbox, TermModal } from 'src/shared/components';

const useStyles = makeStyles((theme) => ({
  switchContainer: {
    padding: '16px 0',
    overflow: 'inherit',
  },
  lgpdContainer: {
    position: 'absolute',
    top: '50%',
    left: 42,
    transform: 'translateY(-50%)',
  },
  lgpd: {
    fontSize: '0.7em',
    display: 'inline',
  },
  link: {
    color: theme.palette.primary.main,
    paddingLeft: 5,
    cursor: 'pointer',
  },
  orangeText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const SignupTerms = ({ isAccepted, acceptTerms }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTermsClick = () => {
    setOpen(true);
  };

  return (
    <>
      <TermModal
        open={open}
        setOpen={setOpen}
        showAcceptButton={!isAccepted}
        acceptTerms={acceptTerms}
        subTitle="Prezado usuário, é muito importante a leitura atenta destes Termos no ato de seu cadastro, pois ao se cadastrar, você estará aceitando as nossas regras."
        paragraphs={[
          {
            key: '1st',
            content: (
              <>
                Em observância às diretrizes da Lei Geral de Proteção de Dados Pessoais (LGPD), o
                Titular/Tomador ou seu representante, de maneira livre, informada e inequívoca
                consente e concorda que a POTTENCIAL SEGURADORA S.A., doravante denominada
                Controladora, tome decisões referentes ao tratamento de seus dados pessoais, bem
                como realize o tratamento de seus dados pessoais, considerando também o legítimo
                interesse da POTTENCIAL SEGURADORA S.A. e a intenção das partes na emissão de
                Apólices de Seguro (execução de contrato) e transações correlatas.
              </>
            ),
          },
          {
            key: '2nd',
            content: (
              <>
                O tratamento dos dados envolverá operações como as que se referem a coleta,
                produção, recepção, classificação, utilização, acesso, reprodução, transmissão,
                distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou
                controle da informação, estando o Titular de acordo com as Políticas da Pottencial
                Seguradora, disponíveis no site desta, incluindo a Política de Privacidade e de
                Segurança da Informação.
              </>
            ),
          },
        ]}
      />
      <div className={classes.switchContainer}>
        <Checkbox checked={isAccepted} disabled={isAccepted} onChange={setOpen} />
      </div>
      <div className={classes.lgpdContainer}>
        <Typography className={classes.lgpd}>
          Concordo com a coleta de dados necessários para o cadastro, de acordo com a{' '}
          <span onClick={handleTermsClick} aria-hidden="true" className={classes.orangeText}>
            Política de Privacidade da Pottencial.
          </span>
        </Typography>
      </div>
    </>
  );
};

SignupTerms.propTypes = {
  isAccepted: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.func.isRequired,
};

export default SignupTerms;
