import { createAsyncThunk } from '@reduxjs/toolkit';
import { loaderActions } from 'src/features/loader/redux';
import { loaderTypes } from 'src/shared/constants/index';
import quizManager from '../quizManager';
import { cleanQuiz } from './quizActions';

export const getQuizAndProductInfo = createAsyncThunk(
  'quiz/GET_PRODUCT_INFO',
  async ({ productKey, quizKey }, { dispatch }) => {
    dispatch(cleanQuiz());
    dispatch(loaderActions.start('getProductInfo'));
    try {
      return await quizManager.getQuizAndProductInfoByKey({ productKey, quizKey });
    } finally {
      dispatch(loaderActions.stop('getProductInfo'));
    }
  }
);

export const createQuote = createAsyncThunk(
  'quiz/CREATE_QUOTE',
  async ({ productKey, properties }, { dispatch }) => {
    dispatch(loaderActions.start({ type: loaderTypes.informative }));
    try {
      if (properties !== undefined)
        delete properties['BROKER_NAME'];
      return await quizManager.createQuote({ productKey, properties });
    } finally {
      dispatch(loaderActions.stop());
    }
  }
);

export const createProposal = createAsyncThunk(
  'quiz/CREATE_PROPOSAL',
  async ({ productKey, quoteId, properties }, { dispatch }) => {
    dispatch(loaderActions.start({ type: loaderTypes.informative }));
    try {
      return await quizManager.createProposal({ productKey, quoteId, properties });
    } finally {
      dispatch(loaderActions.stop());
    }
  }
);

export const verifyInsured = createAsyncThunk(
  'quiz/VERIFY_INSURED',
  async ({ documentNumber, quoteId, insuredSector }, { dispatch }) => {
    dispatch(loaderActions.start('verifyInsured'));
    try {
      return await quizManager.verifyInsured({ documentNumber, quoteId, insuredSector });
    } finally {
      dispatch(loaderActions.stop('verifyInsured'));
    }
  }
);

export const searchBrokerOptions = createAsyncThunk(
  'quiz/SEARCH_BROKER_OPTIONS',
  async (filter) => {
    try {
      return await quizManager.searchBrokerOptions(filter);
    } finally { }
  }
);

export const verifyBrokerAppointment = createAsyncThunk(
  'quiz/VERIFY_BROKER_APPOINTMENT',
  async ({ productKey, cnpjLicitante }, { dispatch }) => {
    dispatch(loaderActions.start('verifyInsured'));
    try {
      return await quizManager.verifyBrokerAppointment({ productKey, cnpjLicitante });
    } finally {
      dispatch(loaderActions.stop('verifyInsured'));
    }
  }
);
