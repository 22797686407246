import React from 'react';
import { AppBar, Footer } from 'src/shared/components';
import Hero from './Hero';
import ProductSelection from './ProductSelection';

const LandingPage = () => (
  <>
    <AppBar showRightButton />
    <Hero />
    <ProductSelection />
    <Footer />
  </>
);

export default LandingPage;
