import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import { isValid, addDays } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { validateDate } from 'src/shared/helpers';

const useStyles = makeStyles({
  datePicker: {
    width: '100%',
    fontWeight: 400,
  },
});

const getValidateProps = (min, max) => {
  const validateDates = {};
  if (min === 0) validateDates.disablePast = true;
  else if (isNumber(min)) validateDates.minDate = addDays(new Date(), min);

  if (max === 0) validateDates.disableFuture = true;
  else if (isNumber(max)) validateDates.maxDate = addDays(new Date(), max);
  return validateDates;
};

const Datepicker = ({ value, onChange, errorMessage, min, max, ...props }) => {
  const classes = useStyles();
  const validateDates = getValidateProps(min, max);

  const handleChange = (date) => {
    const dateWithoutHour = isValid(date) ? new Date(date.setHours(0, 0, 0, 0)) : date;
    onChange(dateWithoutHour, validateDate(date, min, max));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
      <KeyboardDatePicker
        data-testid="date-picker"
        className={classes.datePicker}
        autoOk
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        value={value}
        onChange={handleChange}
        margin="normal"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        invalidDateMessage={errorMessage}
        minDateMessage="Data inválida"
        maxDateMessage="Data inválida"
        {...validateDates}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

Datepicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Datepicker.defaultProps = {
  value: null,
  errorMessage: 'Data inválida',
  onBlur: undefined,
  label: '',
  placeholder: '',
  min: undefined,
  max: undefined,
  disabled: false,
  required: false,
};

export default Datepicker;
