import axios from 'axios';

class SignupService {
  createUser = async (user) => {
    const response = await axios.post(`${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/users`, user);
    return response.data;
  };

  resetUserPassword = async (user) => {
    const response = await axios.post(`${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/users/reset-password`, user);
    return response.data;
  };
}

export default new SignupService();
