import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: 190,
    height: 192,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0 2px 16px hsla(0,0%,80%,.31026)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fef7f4',
      transitionProperty: 'background-color',
      transitionDuration: '.7s',
      transitionTimingFunction: 'cubic-bezier(.5,0,0,1)',
    },
  },
  icon: {
    height: 45,
  },
  text: {
    fontWeight: 500,
    margin: '25px 0 10px',
  },
  quizTitle: {
    fontSize: '0.8em',
    fontWeight: 700,
    color: '#f26522',
  },
}));

const ProductCard = ({ icon, alt, text, onClick }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} onClick={onClick}>
      <div>
        <div>
          <img src={icon} alt={alt} className={classes.icon} />
        </div>
        <div>
          <Typography className={classes.text}>{text}</Typography>
        </div>
        <div>
          <Typography className={classes.quizTitle}>FAZER COTAÇÃO</Typography>
        </div>
      </div>
    </Paper>
  );
};

ProductCard.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProductCard;
