import { merge } from 'lodash';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { DEFAULT_THEME } from 'src/shared/constants';
import MuiBaseConfig from './MuiBaseConfig';

export const createTheme = (color = DEFAULT_THEME) => {
  const configs = {
    name: color.id,
    palette: {
      primary: {
        main: color.primary,
      },
      secondary: {
        main: '#FAFAFA',
        contrastText: '#8B7676',
      },
      text: {
        primary: '#3C3C3C',
        darkBlue: '#303E59',
        lightGray: '#8C8D8E',
      },
      error: {
        main: '#C3284C',
      },
      disabled: {
        main: '#AAAAAA',
      },
    },
  };

  const theme = createMuiTheme(merge({}, MuiBaseConfig, configs));

  return responsiveFontSizes(theme);
};

export default createTheme;
