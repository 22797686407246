import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper, makeStyles, Tooltip, Fade } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import { LabeledText, LabeledTextAsync, NumberInput } from 'src/shared/components';
import { Pencil } from 'src/shared/assets/svg';
import { formatToBrlMoney } from 'src/shared/helpers';
import {
  fullProductNames,
  maxInsuredAmount,
  percentageInsuredAmount,
  productComplement,
} from 'src/shared/constants';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useUpdateQuote } from '../hooks';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 50,
  },
  cardInfo: {
    padding: '8px 0',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 20,
  },
  detailsCard: {
    [theme.breakpoints.up('md')]: {
      marginRight: 50,
    },
  },
  inputLabel: {
    color: '#9DA8B6',
    fontSize: '1rem',
    display: 'block',
  },
  insuredAmountInput: {
    width: '100%',
    marginTop: 0,
  },
  pencil: {
    width: 14,
    display: 'inline-block',
    marginLeft: 10,
  },
  tooltipIcon: {
    cursor: 'pointer',
    marginLeft: 10,
    width: '1rem',
  },
  tooltip: {
    backgroundColor: theme.palette.text.darkBlue,
    fontSize: 12,
    fontFamily: 'Poppins',
    fontWeight: 300,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: { maxWidth: 250 },
  },
  tooltipArrow: {
    color: theme.palette.text.darkBlue,
  },
  legalInfoDiv: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  legalInfo: {
    fontSize: 9,
    color: theme.palette.text.lightGray,
  },
  flexRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
  },
  latestInput: {
    marginBottom: -22,
  },
}));

const QuoteDetailsCard = ({ quote, productKey }) => {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const {
    insuredAmountValue,
    setInsuredAmountValue,
    updateQuote,
    isQuoteLoading,
  } = useUpdateQuote();

  const percentage = percentageInsuredAmount[productKey];

  return (
    <Paper elevation={3} className={clsx(classes.card, classes.detailsCard)}>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="CNPJ do cliente"
          fallback={(label) => (
            <LabeledText label={label}>
              <NumberFormat
                value={quote.policyHolderDocumentNumber}
                displayType="text"
                format="##.###.###/####-##"
                style={{ display: 'block', marginTop: 3 }}
              />
            </LabeledText>
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="Nome do cliente"
          fallback={(label) => (
            <LabeledText label={label} value={quote.policyholderName} className={classes.cardInfo} />
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="CNPJ do segurado"
          fallback={(label) => (
            <LabeledText label={label}>
              <NumberFormat
                value={quote.insuredDocumentNumber}
                displayType="text"
                format="##.###.###/####-##"
                style={{ display: 'block', marginTop: 3 }}
              />
            </LabeledText>
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="Segurado"
          fallback={(label) => (
            <LabeledText label={label} value={quote.insuredName} className={classes.cardInfo} />
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="CNPJ da Corretora"
          fallback={(label) => (
            <LabeledText label={label}>
              <NumberFormat
                value={quote.brokerDocumentNumber}
                displayType="text"
                format="##.###.###/####-##"
                style={{ display: 'block', marginTop: 3 }}
              />
            </LabeledText>
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="Nome da Corretora"
          fallback={(label) => (
            <LabeledText label={label} value={quote.brokerName} className={classes.cardInfo} />
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="Tipo de seguro"
          fallback={(label) => (
            <LabeledText
              label={label}
              value={fullProductNames[productKey]}
              complementValue={productComplement[productKey]}
              className={classes.cardInfo}
            />
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="Vigência"
          fallback={(label) => (
            <LabeledText
              label={label}
              value={quote.duration ? `${quote.duration} dias` : ''}
              className={classes.cardInfo}
            />
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <LabeledTextAsync
          isLoading={isQuoteLoading}
          label="Valor do contrato"
          fallback={(label) => (
            <LabeledText
              label={label}
              value={formatToBrlMoney(quote.contractValue)}
              className={classes.cardInfo}
            />
          )}
        />
      </div>
      <div className={classes.cardInfo}>
        <Typography className={classes.inputLabel}>
          Valor da garantia
          <Tooltip
            open={tooltipOpen}
            onClose={() => setTooltipOpen(false)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={`O valor da garantia é calculado em até ${percentage} do valor do contrato. Valor
            normalmente exigido em licitações públicas.`}
            placement="top"
            arrow
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <HelpOutlineIcon
              aria-label="toogle tooltip"
              onClick={() => setTooltipOpen(!tooltipOpen)}
              className={classes.tooltipIcon}
            />
          </Tooltip>
        </Typography>
        <div className={classes.flexRow}>
          <div className={classes.latestInput}>
            <NumberInput
              key="insured_amount"
              value={insuredAmountValue}
              numberFormatProps={{
                prefix: 'R$ ',
                decimalScale: 2,
                fixedDecimalScale: true,
              }}
              max={maxInsuredAmount}
              onChange={setInsuredAmountValue}
              onBlur={updateQuote}
              classes={{ formControl: classes.insuredAmountInput }}
            />
          </div>
          <img src={Pencil} alt="Pencil" className={classes.pencil} />
        </div>
      </div>
    </Paper>
  );
};

QuoteDetailsCard.propTypes = {
  quote: PropTypes.object.isRequired,
  productKey: PropTypes.string,
};

QuoteDetailsCard.defaultProps = {
  productKey: '',
};

export default QuoteDetailsCard;
