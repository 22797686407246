import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Question, Stepper } from 'src/shared/components';
import { AvatarAssistant } from 'src/shared/assets/svg';
import { productComplement, productNames } from 'src/shared/constants';

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: 60,
  },
  product: {
    textAlign: 'center',
    fontSize: '0.7em',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  productName: {
    marginTop: 20,
  },
  productComplement: {
    marginTop: 0,
    textTransform: 'none',
  },
  question: {
    marginTop: 80,
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  input: {
    marginTop: 100,
    width: 340,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
      width: 310,
    },
  },
  content: {
    maxWidth: 500,
    [theme.breakpoints.down('xs')]: { width: '90%' },
  },
  nextButton: {
    marginTop: 100,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
    },
  },
  previousButton: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
}));

const Quiz = ({
  stepperInfo,
  productKey,
  questionText,
  hideQuestion,
  renderInput,
  renderDescription,
  renderContent,
  renderNextButton,
  renderPreviousButton,
}) => {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.stepper}>
        <Stepper steps={stepperInfo.size} activeStep={stepperInfo.position} />
      </Grid>
      <Grid item xs={12}>
        <Typography data-testid="title" className={clsx(classes.product, classes.productName)}>
          {productNames[productKey]}
        </Typography>
      </Grid>
      {productComplement[productKey] && (
        <Grid item xs={12}>
          <Typography
            data-testid="title"
            className={clsx(classes.product, classes.productComplement)}
          >
            {productComplement[productKey]}
          </Typography>
        </Grid>
      )}
      {!hideQuestion && (
        <>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item className={classes.question}>
                <Question avatar={AvatarAssistant} question={questionText} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item className={classes.input}>
                {renderInput()}
              </Grid>
            </Grid>
          </Grid>
          {renderDescription && (
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item className={classes.content}>
                  {renderDescription()}
                </Grid>
              </Grid>
            </Grid>
          )}
          {renderContent && (
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item className={classes.content}>
                  {renderContent()}
                </Grid>
              </Grid>
            </Grid>
          )}
          {renderNextButton && (
            <Grid item xs={12} className={classes.nextButton}>
              <Grid container justify="center">
                <Grid item>{renderNextButton()}</Grid>
              </Grid>
            </Grid>
          )}
          {renderPreviousButton && (
            <Grid item xs={12} className={classes.previousButton}>
              <Grid container justify="center">
                <Grid item>{renderPreviousButton()}</Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

Quiz.propTypes = {
  stepperInfo: PropTypes.shape({
    size: PropTypes.number,
    position: PropTypes.number,
  }).isRequired,
  productKey: PropTypes.string.isRequired,
  questionText: PropTypes.string,
  hideQuestion: PropTypes.bool,
  renderInput: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
  renderNextButton: PropTypes.func,
  renderPreviousButton: PropTypes.func,
  renderDescription: PropTypes.func,
};

Quiz.defaultProps = {
  questionText: '...',
  hideQuestion: false,
  renderContent: () => { },
  renderNextButton: () => { },
  renderPreviousButton: () => { },
  renderDescription: () => { },
};

export default Quiz;
