import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { CallbackComponent } from 'redux-oidc';
import { loaderActions } from 'src/features/loader/redux';
import { routes } from 'src/shared/constants';
import { authManager } from '../authManager';

const CallbackPage = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loaderActions.start('CallbackPage'));
  }, [dispatch]);

  const successCallback = (user) => {
    const path = user?.state?.callbackUrl ?? routes.home.path;
    push(path);
    dispatch(loaderActions.stop('CallbackPage'));
  };

  return (
    <CallbackComponent successCallback={successCallback} userManager={authManager.getUserManager()}>
      <Grid container style={{ padding: '25%' }} justify="center" alignItems="center">
        <Grid item style={{ padding: '20px 0' }}>
          <Typography variant="body1">Um momento...</Typography>
        </Grid>
      </Grid>
    </CallbackComponent>
  );
};

export default CallbackPage;
