import { authManager } from 'src/features/auth/authManager';

class ProposalService {
  getProposalById = async ({ proposalId, productKey }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/${productKey}/proposals/${proposalId}`
    );

    return response.data;
  };

  acceptProposal = async ({ proposalId, productKey }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/${productKey}/proposals/${proposalId}/accept`
    );

    return response.data;
  };

  issuePolicy = async ({ proposalId, productKey }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/policies`,
      { proposalId, productKey }
    );

    return response.data;
  };

  getProposalDraft = async ({ proposalId, productKey }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/${productKey}/proposals/${proposalId}/draft`
    );

    return response.data;
  };
}

export default new ProposalService();
