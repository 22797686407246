import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getValueFromProperties } from 'src/shared/helpers';
import { routes, fetchStatus, proposalUnderAnalysis } from 'src/shared/constants';
import { getProperties, getProposal } from '../redux/quizSelectors';
import { quizOperations } from '../redux';
import useProductKey from './useProductKey';

export default () => {
  const proposal = useSelector(getProposal);
  const properties = useSelector(getProperties);
  const { productKey } = useProductKey();
  const { quoteId } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (proposal.fetchStatus === fetchStatus.notFetched) return;

    if (proposal.fetchStatus === fetchStatus.fulfilled)
      push(`${routes[productKey].path}${routes.proposal.path}/${proposal.id}`);
    else if (proposal.fetchStatus === (fetchStatus.forbidden || fetchStatus.rejected))
      push(`${routes[productKey].path}${proposalUnderAnalysis}`);
  }, [proposal.fetchStatus, proposal.id, productKey, push]);

  const createProposal = () =>
    dispatch(
      quizOperations.createProposal({
        productKey,
        quoteId,
        properties: { ...getValueFromProperties(properties) },
      })
    );

  return { createProposal };
};
