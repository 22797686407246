import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core';
import { TextInput, CpfInput, MessageBox } from 'src/shared/components';
import { useQuoteLogin } from 'src/features/quote/hooks';
import SignupTerms from './SignupTerms';
import { useSignupForm } from '../hooks';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    height: 40,
  },
  switchContainer: {
    padding: '16px 0',
    overflow: 'inherit',
  },
  lgpdContainer: {
    position: 'absolute',
    top: '50%',
    left: 42,
    transform: 'translateY(-50%)',
  },
  lgpd: {
    fontSize: '0.7em',
    display: 'inline',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 390,
    width: '100%',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    },
  },
  welcomeTitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(8),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  welcome: {
    color: '#333333',
  },
  welcomeSubtitle: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const SignupForm = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const {
    inputValues,
    isFormValid,
    validators,
    handleInputChange,
    handleFormSubmit,
    hasSignupError,
    ackSignupError,
  } = useSignupForm();
  const { redirectToLogin } = useQuoteLogin();

  const { cpf, name, email, accept } = inputValues;
  const { validateName, validateEmail } = validators;

  const handleLogin = () => {
    ackSignupError();
    redirectToLogin();
  };

  return (
    <>
      <MessageBox
        opened={hasSignupError}
        type="error"
        handleClose={ackSignupError}
        labelPrimary="Login"
        handlePrimary={handleLogin}
        title="Falha ao tentar cadastrar o usuário!"
        text="Verifique se o e-mail e o CPF estão corretos. Caso você seja cadastrado, faça seu login."
      />
      <div className={classes.welcomeTitle}>
        <Typography variant="h4" className={classes.welcome}>
          Seja bem-vindo (a)!
        </Typography>
      </div>
      <div className={classes.welcomeSubtitle}>
        <Typography align="center">Novo Cadastro</Typography>
      </div>
      <form noValidate autoComplete="off" className={classes.form}>
        <Grid container>
          <Grid item xs={12}>
            <CpfInput value={cpf.value} onChange={handleInputChange('cpf')} required />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              key="name"
              label="Nome"
              value={name.value}
              onChange={handleInputChange('name')}
              isValid={validateName}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              key="email"
              label="E-mail"
              value={email.value}
              onChange={handleInputChange('email')}
              isValid={validateEmail}
              errorMessage="E-mail inválido"
              required
            />
          </Grid>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <SignupTerms
              isAccepted={accept.value ?? false}
              acceptTerms={() => handleInputChange('accept')(true, true)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between" className={classes.buttonContainer}>
              <Grid item xs={12} sm={4}>
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.button}
                  onClick={goBack}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item sm={1} />
              <Grid item xs={12} sm={7}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  disabled={!isFormValid}
                  onClick={handleFormSubmit}
                >
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SignupForm;
