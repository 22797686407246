import { createReducer } from '@reduxjs/toolkit';
import { fetchStatus, quoteStatus } from 'src/shared/constants';
import * as quizActions from './quizActions';
import * as quizOperations from './quizOperations';

export const INITIAL_STATE = {
  product: {},
  questions: [],
  properties: {},
  quote: {
    fetchStatus: fetchStatus.notFetched,
  },
  proposal: {
    fetchStatus: fetchStatus.notFetched,
    id: null,
    error: {},
  },
  stepperPosition: 0,
  quizFetchStatus: fetchStatus.notFetched,
  insured: {
    isCnpjAllowed: null,
    fetchStatus: fetchStatus.notFetched,
    error: {},
    brokerIsLoading: false,
    brokerOptions: [],
    brokerAppointment: {
      allowBrokerSearch: false,
      brokerAppointmentData: {},
      error: {},
    },
  },
  error: {},
};

const quizReducer = createReducer(INITIAL_STATE, {
  [quizActions.cleanQuiz]: () => INITIAL_STATE,
  [quizActions.cleanErrors]: (state) => ({
    ...state,
    quizFetchStatus: INITIAL_STATE.quizFetchStatus,
    proposal: { ...state.proposal, error: {} },
    insured: { ...state.insured, error: {} },
    error: {},
  }),
  [quizActions.cleanInsured]: (state) => ({
    ...state,
    insured: INITIAL_STATE.insured,
  }),
  [quizOperations.getQuizAndProductInfo.fulfilled]: (state, action) => {
    state.product = action.payload.product;
    state.questions = action.payload.questions;
    state.quizFetchStatus = fetchStatus.fulfilled;
  },
  [quizOperations.getQuizAndProductInfo.rejected]: (state, action) => {
    state.error = action.error;
    state.quizFetchStatus = fetchStatus.rejected;
  },
  [quizActions.setStepperPosition]: (state, action) => {
    state.stepperPosition = action.payload;
  },
  [quizActions.setQuizProperty]: (state, action) => {
    const { name, value, valid } = action.payload;
    state.properties[name] = { value, valid };
  },
  [quizOperations.createQuote.fulfilled]: (state, action) => {
    state.quote.status = action.payload.status;
    state.quote.fetchStatus = fetchStatus.fulfilled;
  },
  [quizOperations.createQuote.rejected]: (state, action) => {
    if (action.error.message.match('400')) {
      state.quote.status = quoteStatus.unauthorized;
      state.quote.fetchStatus = fetchStatus.unauthorized;
    } else {
      state.quote.status = quoteStatus.rejected;
      state.quote.fetchStatus = fetchStatus.rejected;
    }
    state.error = action.error;
  },
  [quizOperations.createProposal.fulfilled]: (state, action) => {
    state.proposal.fetchStatus = fetchStatus.fulfilled;
    state.proposal.id = action.payload;
  },
  [quizOperations.createProposal.rejected]: (state, action) => {
    if (action.error.message.match('403')) {
      state.proposal.fetchStatus = fetchStatus.forbidden;
    } else {
      state.proposal.fetchStatus = fetchStatus.rejected;
    }
    state.proposal.error = action.error;
  },
  [quizActions.setQuoteId]: (state, action) => {
    state.quote.id = action.payload;
  },
  [quizOperations.verifyInsured.fulfilled]: (state, action) => {
    state.insured = {
      isCnpjAllowed: true,
      fetchStatus: fetchStatus.fulfilled,
      error: state.insured.error,
      ...action.payload,
    };
  },
  [quizOperations.verifyInsured.rejected]: (state, action) => {
    state.insured = { fetchStatus: fetchStatus.rejected, ...action.payload };
    if (action.error.message.match('403')) {
      state.insured.isCnpjAllowed = false;
    }
    state.error = action.error;
  },
  [quizActions.setInsuredError]: (state, action) => {
    state.insured.error = action.payload;
  },
  [quizActions.setQuestionContentPreviewText]: (state, action) => {
    const { payload } = action;
    const index = state.questions.findIndex((q) => q.propertyName === payload.propertyName);

    state.questions[index].contentPreview.content = payload.content;
  },
  [quizActions.setQuestionPlaceholder]: (state, action) => {
    const { payload } = action;
    const index = state.questions.findIndex((q) => q.propertyName === payload.propertyName);

    state.questions[index].placeholder = payload.placeholder;
  },
  [quizActions.setQuestionTitle]: (state, action) => {
    const { payload } = action;
    const index = state.questions.findIndex((q) => q.propertyName === payload.propertyName);
    state.questions[index].title = payload.title;
  },
  [quizActions.setQuestionBypass]: (state, action) => {
    const { payload } = action;
    const index = state.questions.findIndex((q) => q.propertyName === payload.propertyName);
    state.questions[index].bypass = payload.bypassQuestion;
  },
  [quizActions.setQuestionRequired]: (state, action) => {
    const { payload } = action;
    const index = state.questions.findIndex((q) => q.propertyName === payload.propertyName);
    state.questions[index].required = payload.requiredQuestion;
  },
  [quizOperations.searchBrokerOptions.pending]: (state) => {
    state.insured.brokerIsLoading = true;
    state.insured.brokerOptions = [];
  },
  [quizOperations.searchBrokerOptions.fulfilled]: (state, action) => {
    state.insured.brokerIsLoading = false;
    state.insured.brokerOptions = action.payload;
  },
  [quizOperations.searchBrokerOptions.rejected]: (state, action) => {
    state.insured.brokerIsLoading = false;
    state.insured.brokerOptions = [];
    state.error = action.error;
  },
  [quizOperations.verifyBrokerAppointment.pending]: (state) => {
    state.insured.brokerAppointment.allowBrokerSearch = false;
    state.insured.brokerAppointment.brokerAppointmentData = {};
  },
  [quizOperations.verifyBrokerAppointment.fulfilled]: (state, action) => {
    state.insured.brokerAppointment.allowBrokerSearch = !action.payload.isActual;
    state.insured.brokerAppointment.brokerAppointmentData = action.payload;
  },
  [quizOperations.verifyBrokerAppointment.rejected]: (state, action) => {
    state.insured.brokerAppointment.allowBrokerSearch = false;
    state.insured.brokerAppointment.brokerAppointmentData = {};
    state.error = action.error;
  },
});

export default quizReducer;
