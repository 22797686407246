import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { MessageBox } from 'src/shared/components';
import { fetchStatus , routes } from 'src/shared/constants';
import { signupOperations } from '../redux/signupSlice';
import { signupActions } from '../redux';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 390,
    width: '100%',
  },
  btn: {
    padding: theme.spacing(1),
  },
  changeLink: {
    fontSize: '.8rem',
    textDecoration: 'none',
    '& svg': {
      marginLeft: 2,
      fontSize: '.8rem',
    },
  },
  welcomeTitle: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

const SignupConfirm = () => {
  const classes = useStyles();
  const { email } = useParams();
  const decryptedMail = useMemo(() => atob(email), [email]);
  const dispatch = useDispatch();
  const {
    fetchStatus: { resetUserPassword: resetUserPasswordStatus },
  } = useSelector(({ signup }) => signup);
  const [modalInfo, setModalInfo] = useState({ title: '', text: '', type: 'success' });

  useEffect(() => {
    if (resetUserPasswordStatus === fetchStatus.fulfilled) {
      setModalInfo({
        title: 'E-mail enviado com sucesso',
        text: 'Obrigado, em instantes você deve receber um e-mail de confirmação e troca de senha.',
        type: 'success',
      });
    } else if (resetUserPasswordStatus === fetchStatus.rejected) {
      setModalInfo({
        title: 'Erro ao reenviar o e-mail',
        text:
          'Ocorreu um erro ao tentar reenviar o e-mail. Se o problema persistir entre em contato em um dos canais de comunicação.',
        type: 'error',
      });
    }
  }, [setModalInfo, resetUserPasswordStatus]);

  const handleConfirmation = async () => {
    const payload = {
      email: decryptedMail,
      returnUrl: `${window.env.REACT_APP_HOME_URL  }${routes.quote.path}`,
    }
    await dispatch(signupOperations.resetUserPassword(payload));
  };

  const handleCloseModal = () => {
    dispatch(signupActions.resetFetchStatus());
  };

  const isModalOpen = () =>
    resetUserPasswordStatus === fetchStatus.rejected ||
    resetUserPasswordStatus === fetchStatus.fulfilled;

  return (
    <>
      <MessageBox
        opened={isModalOpen()}
        type={modalInfo.type}
        handleClose={handleCloseModal}
        handleSecondary={handleCloseModal}
        labelSecondary="Fechar"
        title={modalInfo.title}
        text={modalInfo.text}
      />
      <div className={classes.root}>
        <div className={classes.welcomeTitle}>
          <Typography variant="h4">Verifique seu e-mail</Typography>
        </div>

        <Box mb={8} align="center">
          <Typography align="center">
            <Box component="span">
              Para concluir seu cadastro acesse o link enviado para o e-mail
            </Box>
            <Box component="span" ml={1}>
              {decryptedMail}
            </Box>
          </Typography>
        </Box>

        <Box mb={2} align="center">
          <Button
            color="primary"
            variant="outlined"
            onClick={handleConfirmation}
            title="Reenviar e-mail de confirmação"
          >
            Reenviar e-mail de confirmação
          </Button>
        </Box>
      </div>
    </>
  );
};

export default SignupConfirm;
