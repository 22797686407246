import { isString } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { loaderTypes } from 'src/shared/constants/index';

const INITIAL_STATE = {
  isLoading: false,
  loading: [],
  type: loaderTypes.default,
  loadingText: 'Aguarde um instante, estamos analisando sua solicitação...',
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState: INITIAL_STATE,
  reducers: {
    start(state, action) {
      if (isString(action.payload)) {
        state.loading.push(action.payload);
        state.type = loaderTypes.default;
      } else {
        state.isLoading = true;
        state.type = action.payload.type;
        state.loadingText = action.payload.loadingText ?? INITIAL_STATE.loadingText;
      }
    },
    stop(state, action) {
      if (isString(action.payload)) {
        state.loading = state.loading.filter((el) => el !== action.payload);
      } else {
        state.isLoading = INITIAL_STATE.isLoading;
      }
      state.type = INITIAL_STATE.type;
      state.loadingText = INITIAL_STATE.loadingText;
    },
  },
});

export default loaderSlice;
