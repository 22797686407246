import { isNumber } from 'lodash';
import { isValid, isWithinInterval, addDays, isAfter, isBefore } from 'date-fns';

export default (date, min, max) => {
  if (isNumber(min) && isNumber(max) && min > max) throw new Error('Invalid boundaries');
  const today = new Date().setHours(0, 0, 0, 0);
  if (isNumber(min) && isNumber(max)) {
    return (
      isValid(date) &&
      isWithinInterval(date, { start: addDays(today, min), end: addDays(today, max) })
    );
  }
  if (isNumber(min)) {
    return isValid(date) && isAfter(date, addDays(today, min));
  }
  if (isNumber(max)) {
    return isValid(date) && isBefore(date, addDays(today, max));
  }
  return isValid(date);
};
