import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { routes, fetchStatus, proposalUnderAnalysis } from 'src/shared/constants';
import { getProductKeyByPathname } from 'src/shared/helpers';
import * as quoteOperations from '../redux/quoteOperations';

export default () => {
  const { push, location } = useHistory();
  const dispatch = useDispatch();
  const proposal = useSelector(({ quote }) => quote.proposal);
  const productKey = getProductKeyByPathname(location.pathname);
  const { quoteId } = useParams();

  useEffect(() => {
    if (proposal.fetchStatus === fetchStatus.notFetched) return;

    if (proposal.fetchStatus === fetchStatus.fulfilled)
      push(`${routes[productKey].path}${routes.proposal.path}/${proposal.id}`);
    else if (proposal.fetchStatus === (fetchStatus.forbidden || fetchStatus.rejected))
      push(`${routes[productKey].path}${proposalUnderAnalysis}`);
  }, [proposal.fetchStatus, proposal.id, productKey, push]);

  const createProposal = () =>
    dispatch(
      quoteOperations.createProposal({
        productKey,
        quoteId,
      })
    );

  return { createProposal };
};
