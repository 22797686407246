import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Landing } from 'src/shared/assets/img';
import { DEFAULT_WIDTH, DEFAULT_HERO_SIZE, DEFAULT_HERO_SIZE_MOBILE } from 'src/shared/constants';

const useStyles = makeStyles((theme) => ({
  bgDiv: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: `url(${Landing})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center right 40%',
    [theme.breakpoints.down('sm')]: { backgroundPosition: 'center right 30%' },
  },
  textBlock: {
    maxWidth: DEFAULT_WIDTH,
    width: '100%',
    height: DEFAULT_HERO_SIZE,
    [theme.breakpoints.down('xs')]: { height: DEFAULT_HERO_SIZE_MOBILE },
    display: 'flex',
    color: '#fff',
    fontWeight: 700,
    textShadow: '6px 10px 8px #00000029',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: { paddingLeft: 24, paddingRight: 24 },
  },
  title: {
    color: '#FFFFFF',
    fontSize: '4em',
    fontFamily: 'Poppins',
    fontWeight: 700,
    lineHeight: 1.3,
    letterSpacing: -2,
    [theme.breakpoints.down('sm')]: { fontSize: '3em' },
    [theme.breakpoints.down('xs')]: { fontSize: '2.5em' },
  },
}));

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.bgDiv}>
      <div className={classes.textBlock}>
        <Typography className={classes.title} component="h1">
          Faça sua cotação
          <br />
          online de
          <br />
          Seguro Garantia!
        </Typography>
      </div>
    </div>
  );
};

export default Hero;
