import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { proposalStatus } from 'src/shared/constants';
import { proposalOperations, proposalActions } from 'src/features/proposal/redux/';
import {
  getAcceptProposalFetchError,
  getProposal,
} from 'src/features/proposal/redux/proposalSelector';

export default () => {
  const dispatch = useDispatch();
  const proposal = useSelector(getProposal);
  const hasAcceptError = useSelector(getAcceptProposalFetchError);
  const [isErrorModalOpen, openErrorModalOpen] = useState(false);

  useEffect(() => {
    if (hasAcceptError) {
      openErrorModalOpen(true);
    }
  }, [openErrorModalOpen, hasAcceptError]);

  const ackAcceptError = () => {
    dispatch(proposalActions.cleanAcceptedFetchStatus());
    openErrorModalOpen(false);
  };

  const proposalCanBeAccepted = proposal.status === proposalStatus.issued;
  const isProposalAccepted =
    proposal.status === proposalStatus.accepted || proposal.status === proposalStatus.done;

  const acceptProposal = async () => {
    if (proposalCanBeAccepted) {
      dispatch(
        proposalOperations.acceptProposal({
          productKey: proposal.productKey,
          proposalId: proposal.proposalId,
        })
      );
    }
  };

  return {
    isProposalAccepted,
    proposalCanBeAccepted,
    acceptProposal,
    isErrorModalOpen,
    ackAcceptError,
  };
};
