import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchStatus, routes } from 'src/shared/constants';
import { getProductKeyByPathname } from 'src/shared/helpers';
import { getProposalById } from 'src/features/proposal/redux/proposalOperations';
import { getProposal, getProposalFetchError } from 'src/features/proposal/redux/proposalSelector';

export default () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const { proposalId } = useParams();

  const productKey = getProductKeyByPathname(location.pathname);
  const proposal = useSelector(getProposal);
  const hasGetError = useSelector(getProposalFetchError);
  const isProposalLoading = proposal.fetchStatus.getProposalById !== fetchStatus.fulfilled;

  useEffect(() => {
    if (hasGetError) push(routes.error.path);
    else {
      dispatch(getProposalById({ proposalId, productKey }));
    }
  }, [push, hasGetError, dispatch, productKey, proposalId]);

  return { proposal: { ...proposal, proposalId }, isProposalLoading };
};
