import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Approved } from 'src/shared/assets/svg';
import { useHistory } from 'react-router-dom';
import { routes } from 'src/shared/constants';
import { useStyles } from './styles';

const AlreadyApproved = () => {
  const classes = useStyles();
  const { push } = useHistory();

  const returnToHome = useCallback(() => {
    push(routes.home.path);
  }, [push]);

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography className={classes.title}>
          Já identificamos o aceite para emissão da sua apólice!
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.alignCenter}>
        <img src={Approved} alt="Approved" className={classes.image} />
      </Grid>

      <Grid item xs={10} md={4} lg={4} xl={3} className={classes.alignCenter}>
        <Typography className={classes.textInfo}>
          Enviaremos um e-mail assim que o processo de emissão for concluído.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center" className={classes.btnContainer}>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={returnToHome}
              className={classes.button}
            >
              Voltar ao início
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlreadyApproved;
