import * as yup from 'yup';
import { productKeys, quoteStatus } from 'src/shared/constants';

export const approvedQuoteValidator = yup.object().shape({
  quoteId: yup.string().uuid().required(),
  productKey: yup.string().oneOf(productKeys).required(),
  status: yup.string().oneOf(['APPROVED']).required(),
});

export const getCustomQuoteValidator = (_productKeys, status) =>
  yup.object().shape({
    quoteId: yup.string().uuid().required(),
    productKey: yup
      .string()
      .oneOf(_productKeys ?? productKeys)
      .required(),
    status: yup
      .string()
      .oneOf(status ?? Object.values(quoteStatus))
      .required(),
  });
