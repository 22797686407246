import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { string } from 'yup';
import { routes, fetchStatus } from 'src/shared/constants';
import { getValueFromProperties } from 'src/shared/helpers';
import { signupActions, signupSelector, signupOperations } from '../redux';

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const {
    cpf,
    name,
    email,
    accept,
    fetchStatus: { createUser: createUserStatus },
  } = useSelector(({ signup }) => signup);
  const isFormValid = useSelector(signupSelector.isValid);

  useLayoutEffect(() => {
    if (createUserStatus === fetchStatus.fulfilled) {
      push(`${routes.signup.path}/${btoa(email.value)}`);
    }
  }, [createUserStatus, push, email.value]);

  const handleInputChange = (key) => (value, valid) => {
    dispatch(signupActions.setValue({ key, value: { value, valid } }));
  };

  const handleFormSubmit = async () => {
    const payload = {
      ...getValueFromProperties({ cpf, email, name }),
      returnUrl: `${window.env.REACT_APP_HOME_URL  }${routes.quote.path}`,
    }
    dispatch(signupOperations.createUser(payload));
  };

  const hasSignupError = createUserStatus === fetchStatus.rejected;
  const ackSignupError = () => dispatch(signupActions.resetFetchStatus());

  const validators = {
    validateEmail: (value) => {
      try {
        return !!string().email().validateSync(value);
      } catch (error) {
        return false;
      }
    },
    validateName: (value) => {
      try {
        return !!string().min(6).max(120).validateSync(value);
      } catch (error) {
        return false;
      }
    },
  };

  const inputValues = { cpf, name, email, accept };

  return {
    inputValues,
    isFormValid,
    validators,
    handleInputChange,
    handleFormSubmit,
    hasSignupError,
    ackSignupError,
  };
};
