import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Button, Typography, Grid, makeStyles } from '@material-ui/core';
import { CheckedFile } from 'src/shared/assets/svg';
import { productComplement, productNames } from 'src/shared/constants';
import { useGoogleAnalytics } from 'src/shared/hooks';
import usePolicy from '../hooks/usePolicy';

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: 35,
  },
  product: {
    textAlign: 'center',
    fontSize: '0.7rem',
    letterSpacing: 1,
  },
  productName: {
    marginTop: 30,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 22,
    color: theme.palette.text.darkBlue,
    textAlign: 'center',
    marginTop: 35,
  },
  image: {
    height: 115,
    marginTop: 25,
  },
  alignCenter: {
    textAlign: 'center',
  },
  btnContainer: {
    marginTop: 20,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  button: {
    width: 200,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 25,
    },
  },
  knowOurProductsBtn: {
    width: 250,
  },
  textContent: {
    fontSize: '1em',
    color: theme.palette.text.primary,
    marginTop: 60,
  },
  textInfo: {
    fontSize: '0.8em',
    color: theme.palette.text.lightGray,
    marginTop: 30,
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  rootStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    margin: '0 15px',
    backgroundColor: theme.palette.primary.main,
  },
  anchor: {
    textDecoration: 'none',
  },
}));

const PolicyIssuedPage = () => {
  const classes = useStyles();
  const { policyUrl, productKey } = usePolicy();
  const { event } = useGoogleAnalytics();

  const knowOurProductsUrl = 'https://www.pottencial.com.br/#section-products';

  useEffect(() => {
    event('Policy', 'PolicyIssued', `${productNames[productKey]}`);
  }, [event, productKey]);

  const eventKnowOurProducts = () => {
    event('LeftPage', 'KnowOurProducts', '');
  };

  const eventDownloadPolicy = () => {
    event('Download', 'DownloadPolicy', `${productNames[productKey]}`);
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.productName}>
        <Typography data-testid="title" className={clsx(classes.product, classes.productName)}>
          {productNames[productKey]}
        </Typography>
      </Grid>
      {productComplement[productKey] && (
        <Grid item xs={12}>
          <Typography data-testid="productName" className={classes.product}>
            {productNames[productKey] ? productComplement[productKey] : ''}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography className={classes.title}>Apólice emitida com sucesso!</Typography>
      </Grid>
      <Grid item xs={12} className={classes.alignCenter}>
        <img src={CheckedFile} alt="Apólice emitida" className={classes.image} />
      </Grid>
      <Grid item xs={10} md={4} xl={3} className={classes.alignCenter}>
        <Typography className={classes.textContent}>
          Obrigada por escolher a Pottencial! Continue contando com nossos seguros para viabilizar
          seus negócios!
        </Typography>
      </Grid>
      <Grid container justify="center" className={classes.btnContainer}>
        {policyUrl && (
          <Grid item xs={12}>
            <div className={classes.divButton}>
              <a className={classes.anchor} href={policyUrl} download="apolice.pdf">
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={eventDownloadPolicy}
                >
                  Download da Apólice
                </Button>
              </a>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={classes.divButton}>
            <a className={classes.anchor} href={knowOurProductsUrl}>
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.button, classes.knowOurProductsBtn)}
                onClick={eventKnowOurProducts}
              >
                Conheça nossos produtos
              </Button>
            </a>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.textInfo}>
            Uma cópia da apólice, do boleto e das certidões foram encaminhadas para o seu e-mail.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PolicyIssuedPage;
