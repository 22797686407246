import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as quoteOperations from 'src/features/quote/redux/quoteOperations';
import { Typography, makeStyles } from '@material-ui/core';
import { Switch } from 'src/shared/components';

const useStyles = makeStyles((theme) => ({
  additionalText: {
    marginLeft: 40,
    fontWeight: 600,
    color: theme.palette.text.primary,
    fontFamily: '"Open Sans", sans-serif',
  },
  switch: {
    position: 'absolute',
    transform: 'translate(0, -50%)',
    top: '50%',
  },
}));

const AdditionalCoverageCard = ({ quote, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSwitchChange = (newValue) => {
    dispatch(
      quoteOperations.updateQuote({
        quoteId: quote.quoteId,
        productKey: quote.productKey,
        insuredAmount: quote.insuredAmount,
        additionalCoverageIncluded: newValue,
      })
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <Switch
        className={classes.switch}
        checked={quote?.additionalCoverageIncluded ?? false}
        onChange={handleSwitchChange}
        disabled={disabled}
      />
      <Typography className={classes.additionalText}>
        O contrato exige cobertura adicional trabalhista?
      </Typography>
    </div>
  );
};

AdditionalCoverageCard.propTypes = {
  quote: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

AdditionalCoverageCard.defaultProps = {
  disabled: false,
};

export default AdditionalCoverageCard;
