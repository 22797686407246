import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Quiz } from 'src/shared/components';
import { useGoogleAnalytics } from 'src/shared/hooks';
import { setQuizProperty } from '../redux/quizActions';
import InputFactory from './InputFactory';
import ContentPreview from './ContentPreview';
import DescriptionPreview from './DescriptionPreview';
import { useProduct, useQuiz } from '../hooks';

const CustomQuiz = ({
  quizKey,
  nextPage,
  previousPage,
  onBlur,
  renderInputComplement,
  disableNext,
  additionalProps,
  additionalDispatches,
  defaultValues,
}) => {
  const dispatch = useDispatch();
  const { event } = useGoogleAnalytics();

  const { product } = useProduct({ quizKey });
  const { activeQuestion, activeProperty, stepperInfo } = useQuiz();

  const { size, position } = stepperInfo;
  const isPropertyValid = activeProperty?.valid ?? false;
  const inputValue = activeProperty?.value ?? '';

  const setInputProperty = (value, valid = true) => {
    if (activeQuestion.propertyName !== 'BROKER_NAME') {
      dispatch(setQuizProperty({ name: activeQuestion.propertyName, value, valid }));
    } else {
      dispatch(setQuizProperty({ name: 'BROKER_DOCUMENT', value: value?.documentNumber, valid }));
    }
    additionalDispatches(value);
  };

  const nextButtonDisabled = () => {
    if (disableNext) return true;
    if (position >= size - 1) return true;
    if (activeQuestion.required && !isPropertyValid) return true;
    return false;
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13 && !nextButtonDisabled()) {
      nextPage();
    }
  };

  const eventPreviousPage = () => {
    event('Quiz', 'PreviousPage', `${product.productName} - ${activeQuestion.propertyName}`);
  };

  useEffect(() => {
    if (!inputValue) {
      const defaultValue = defaultValues.find((df) => df.propertyName === activeQuestion.propertyName);
      if (defaultValue) setInputProperty(defaultValue.value, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestion, defaultValues])

  return (
    <Quiz
      stepperInfo={{ size, position }}
      productName={product.productName}
      productKey={product.productKey}
      questionText={activeQuestion.title}
      hideQuestion={activeQuestion.bypass}
      renderInput={() => (
        <>
          <InputFactory
            question={activeQuestion}
            value={inputValue}
            setValue={setInputProperty}
            onKeyDown={onKeyDownHandler}
            onBlur={onBlur}
            autoFocus
            {...additionalProps}
          />
          {renderInputComplement && renderInputComplement()}
        </>
      )}
      renderDescription={() => {
        if (activeQuestion.useDescriptionAsToolTip) return null;

        return <DescriptionPreview description={activeQuestion.description ?? ''} icon={activeQuestion.icon ?? ''} />;
      }}
      renderContent={() => {
        if (activeQuestion.contentPreview) {
          return (
            <ContentPreview
              propertyName={activeQuestion.propertyName ?? ''}
              inputValue={inputValue}
              contentPreview={activeQuestion?.contentPreview}
              placeholder={activeQuestion?.placeholder}
            />
          );
        }
        return null;
      }}
      renderNextButton={() => (
        <Button
          color="primary"
          variant="contained"
          onClick={nextPage}
          disabled={nextButtonDisabled()}
        >
          Continuar
        </Button>
      )}
      renderPreviousButton={() => (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            previousPage();
            eventPreviousPage();
          }}
        >
          Voltar
        </Button>
      )}
    />
  );
};

CustomQuiz.propTypes = {
  quizKey: PropTypes.string.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  renderInputComplement: PropTypes.func,
  disableNext: PropTypes.bool,
  additionalProps: PropTypes.object,
  additionalDispatches: PropTypes.func,
  defaultValues: PropTypes.array,
};

CustomQuiz.defaultProps = {
  onBlur: () => { },
  renderInputComplement: null,
  disableNext: false,
  additionalProps: {},
  additionalDispatches: () => { },
  defaultValues: [],
};

export default CustomQuiz;
