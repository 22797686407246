import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routes } from 'src/shared/constants';
import { getQuote } from '../redux/quoteSelector';
import { approvedQuoteValidator } from '../redux/quoteValidators';

const QuoteRoute = () => {
  try {
    const quote = useSelector(getQuote);
    approvedQuoteValidator.validateSync(quote);
    return (
      <Redirect to={`${routes[quote.productKey].path}${routes.quote.path}/${quote.quoteId}`} />
    );
  } catch (error) {
    return <Redirect to={`${routes.error.path}`} />;
  }
};

export default QuoteRoute;
