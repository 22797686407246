import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { LabeledText } from 'src/shared/components';

const LabeledTextAsync = ({ isLoading, fallback, label, width, height }) => {
  if (isLoading) {
    return (
      <LabeledText data-testid="skeleton" label={label}>
        <Skeleton animation="wave" width={width} height={height} variant="rect" />
      </LabeledText>
    );
  }

  return fallback(label);
};

LabeledTextAsync.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fallback: PropTypes.func.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

LabeledTextAsync.defaultProps = {
  label: '',
  width: 250,
  height: 25,
};

export default LabeledTextAsync;
