import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { routes, productNames, productComplement } from 'src/shared/constants';
import { Button, Typography, MobileStepper, Grid, makeStyles } from '@material-ui/core';
import { UnderEvaluation } from 'src/shared/assets/svg';
import { useQuizSize, useGoogleAnalytics } from 'src/shared/hooks';
import { getQuote } from 'src/features/quote/redux/quoteSelector';
import { getProposalRejected } from '../redux/proposalSelector';

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: 40,
  },
  product: {
    textAlign: 'center',
    fontSize: '0.7rem',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  productName: {
    marginTop: 5,
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 22,
    color: theme.palette.text.darkBlue,
    textAlign: 'center',
    marginTop: 35,
    [theme.breakpoints.down('xs')]: {
      fontSize: '5vw',
    },
  },
  image: {
    width: 150,
    marginTop: 25,
  },
  alignCenter: {
    textAlign: 'center',
  },
  btnContainer: {
    marginTop: 10,
    textAlign: 'center',
  },
  button: {
    width: 200,
    marginTop: 20,
  },
  textInfo: {
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    marginTop: 60,
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  rootStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    margin: '0 15px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProposalUnderAnalysis = () => {
  const classes = useStyles();
  const size = useQuizSize();
  const { productKey } = useSelector(getQuote);
  const { push } = useHistory();
  const { event } = useGoogleAnalytics();
  const wasProposalRejected = useSelector(getProposalRejected);

  useEffect(() => {
    if (wasProposalRejected) {
      event('proposal', 'ProposalUnderAnalysis', `${productNames[productKey]}`);
    }
  }, [wasProposalRejected, event, productKey]);

  const returnToHome = useCallback(() => {
    push(routes.home.path);
  }, [push]);

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.stepper}>
        <MobileStepper
          steps={size}
          variant="dots"
          position="static"
          className={classes.root}
          classes={{ dot: classes.dot, root: classes.rootStep }}
          data-testid="finished-stepper"
        />
      </Grid>

      <Grid item xs={12} className={classes.productName}>
        <Typography data-testid="title" className={clsx(classes.product, classes.productName)}>
          {productNames[productKey]}
        </Typography>
      </Grid>
      {productComplement[productKey] && (
        <Grid item xs={12}>
          <Typography data-testid="productName" className={classes.product}>
            {productComplement[productKey]}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography className={classes.title}>Precisamos avaliar mais de perto</Typography>
      </Grid>
      <Grid item xs={12} className={classes.alignCenter}>
        <img src={UnderEvaluation} alt="Under Evaluation" className={classes.image} />
      </Grid>
      <Grid item xs={10} md={4} lg={4} xl={3} className={classes.alignCenter}>
        <Typography className={classes.textInfo}>
          Não foi possível prosseguir com as informações fornecidas.
          <br />
          Mas não se preocupe, entraremos em contato com você.
        </Typography>
      </Grid>
      <Grid container justify="center" className={classes.btnContainer}>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={returnToHome}
            className={classes.button}
          >
            Voltar para o início
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProposalUnderAnalysis.propTypes = {};

export default ProposalUnderAnalysis;
