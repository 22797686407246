import { createSlice } from '@reduxjs/toolkit';

export const authSelector = {
  isUserLogged: ({ oidc }) => !!oidc.user,
  getAxiosAuthorized: ({ auth }) => auth.isAxiosAuthorized,
};

export const INITIAL_STATE = {
  isLoggingOut: false,
  isAxiosAuthorized: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setLoggingOut: (state, action) => {
      state.isLoggingOut = action.payload;
    },
    setIsAxiosAuthorized: (state, action) => {
      state.isAxiosAuthorized = action.payload;
    },
  },
});

export default authSlice;
