import axios from 'axios';
import { authManager } from 'src/features/auth/authManager';

class QuizService {
  getQuizAndProductInfoByKey = async ({ productKey, quizKey }) => {
    const axiosInstance = quizKey === 'quote' ? axios : authManager.getAxiosInstance();
    const response = await axiosInstance.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/products/${productKey}/${quizKey}`
    );
    return response.data;
  };

  createQuote = async (quoteRequest) => {
    const response = await axios.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/quotes`,
      quoteRequest
    );
    return response.data;
  };

  createProposal = async (proposalRequest) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/proposals`,
      proposalRequest
    );
    return response.data;
  };

  verifyInsured = async ({ documentNumber, quoteId, insuredSector }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const config = {
      headers: {
        'Insured-Sector': insuredSector ?? '',
      },
    };

    const response = await axiosInstance.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/insureds`,
      { cnpj: documentNumber, quoteId },
      config
    );

    return { ...response.data, status: response.status };
  };

  getQuoteById = async ({ quoteId, productKey }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/${productKey}/quotes/${quoteId}`
    );

    return response.data;
  };

  searchBrokerOptions = async (searchFilterRequest) => {
    const response = await axios.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/brokers/search`,
      searchFilterRequest
    );
    return response.data;
  };

  verifyBrokerAppointment = async ({ productKey, cnpjLicitante }) => {
    const response = await axios.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/brokers/${productKey}/verify-appointment/${cnpjLicitante}`
    );
    return response.data;
  };
}

export default new QuizService();
