import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Button, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { routes } from 'src/shared/constants';
import { useGoogleAnalytics } from 'src/shared/hooks';
import { BrokerArea } from 'src/shared/assets/img';
import { BeamBallance, LegalDocument, Order } from 'src/shared/assets/svg';
import { quizActions } from 'src/features/quiz/redux';
import ProductCard from './ProductCard';

const borderStyle = '1px dashed';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    textAlign: 'center',
    fontSize: '2.5em',
    fontWeight: 600,
    opacity: 0.7,
    padding: '30px 0',
    [theme.breakpoints.down('sm')]: { fontSize: '2em' },
    [theme.breakpoints.down('xs')]: { fontSize: '1.2em' },
  },
  isBrokerImage: {
    maxWidth: '300px',
  },
  isBrokerContent: {
    maxWidth: '500px',
  },
  isBrokerTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    opacity: 0.7,
  },
  isBrokerText: {
    fontSize: '16px',
    opacity: 0.7,
  },
  container: {
    width: '100%',
    margin: 0,
    marginBottom: 100,
  },
  helperText: {
    color: '#666666',
    letterSpacing: 0,
    width: 190,
    padding: 10,
    fontSize: '0.9em',
    textAlign: 'center',
  },
  divDashed: {
    display: 'flex',
    height: '100%',
  },
  divDashedLeft: {
    justifyContent: 'flex-end',
  },
  divDashedRight: {
    justifyContent: 'center',
    flexDirection: 'column-reverse',
  },
  dashedLine: {
    width: '70%',
    opacity: 0.2,
  },
  dashedLineLeftTop: {
    height: '240px',
    borderLeft: borderStyle,
    borderBottom: borderStyle,
    borderBottomLeftRadius: 10,
  },
  dashedLineLeftBottom: {
    height: '240px',
    borderLeft: borderStyle,
    borderTop: borderStyle,
    borderTopLeftRadius: 10,
  },
  dashedLineRight: {
    height: '100%',
    margin: '240px 0',
    borderRight: borderStyle,
    borderTop: borderStyle,
    borderBottom: borderStyle,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  dashedLeftContainer: {
    minHeight: '100%',
  },
  line: {
    display: 'flex',
    margin: '0px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '90px',
    height: '2px',
    background: theme.palette.primary.main,
  },
  button: {
    marginTop: 20,
  },
}));

const ProductSelection = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { event } = useGoogleAnalytics();

  useEffect(() => {
    dispatch(quizActions.cleanQuiz());
  }, [dispatch]);

  const eventJudicialRecursal = () => {
    event('LeftPage', 'JudicialRecursal');
  };

  const cards = [
    {
      icon: LegalDocument,
      alt: 'Cotação Online Seguro Garantia Licitações - Pottencial Seguradora',
      text: 'Garantia do Licitante (BID)',
      onClick: () => push(routes.garantiaBidPublico.path),
      helperText:
        'Vai participar de uma licitação? O nosso Seguro Garantia BID é o ideal para você!',
    },
    {
      icon: Order,
      alt: 'Cotação Online Seguro Garantia Performance - Pottencial Seguradora',
      text: 'Garantia Performance',
      onClick: () => push(routes.garantiaPerformancePublico.path),
      helperText:
        'Vai assinar um contrato de construção, fornecimento ou prestação de serviço? Escolha o Seguro Garantia Performance.',
    },
    {
      icon: BeamBallance,
      alt: 'Cotação Online Seguro Garantia Depósito Recursal - Pottencial Seguradora',
      text: 'Judicial Depósito Recursal',
      onClick: () => {
        eventJudicialRecursal();
        window.open(window.env.REACT_APP_SITE_RECURSAL, '_blank');
      },
      helperText:
        'Não comprometa o capital de giro da sua empresa! Contrate o seguro Judicial Depósito Recursal e fique resguardado!',
    },
  ];

  const redirectToBrokerArea = () => {
    window.location.assign(window.env.PORTAL_CORRETOR);
  };

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="stretch">
        <Hidden mdDown>
          <Grid item md={false} lg xl>
            <Grid
              container
              direction="column"
              justify="space-between"
              className={classes.dashedLeftContainer}
            >
              <Grid item xs="12">
                <div className={clsx(classes.divDashed, classes.divDashedLeft)}>
                  <div className={clsx(classes.dashedLine, classes.dashedLineLeftTop)} />
                </div>
              </Grid>
              <Grid item xs="12">
                <div className={clsx(classes.divDashed, classes.divDashedLeft)}>
                  <div className={clsx(classes.dashedLine, classes.dashedLineLeftBottom)} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Grid item md={12} lg={8} xl={6}>
          <Typography className={classes.title} component="h2"> Qual Seguro Garantia você precisa?</Typography>
          <Grid container justify="center" spacing={5} className={classes.container}>
            {cards.map((card) => (
              <Grid item key={card.text}>
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <ProductCard {...card} />
                  </Grid>
                </Grid>
                <Typography className={classes.helperText}>{card.helperText}</Typography>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={5}
            className={classes.container}
          >
            <Grid item md="4" xs="12" className={classes.isBrokerImage}>
              <img className={classes.logo} src={BrokerArea} alt="Logo - Pottencial Seguradora" />
            </Grid>
            <Grid item md="6" xs="12" className={classes.isBrokerContent}>
              <Typography className={classes.isBrokerTitle} component="h2">É corretor?</Typography>
              <span className={classes.line} />
              <Typography className={classes.isBrokerText}>
                Acesse o Portal do Corretor, nossa plataforma feita especialmente para você, que já
                trabalha conosco ou que deseja se tornar nosso parceiro.
              </Typography>

              <Button
                color="primary"
                variant="outlined"
                onClick={redirectToBrokerArea}
                className={classes.button}
              >
                PORTAL DO CORRETOR
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Hidden mdDown>
          <Grid item md={false} lg xl>
            <div className={clsx(classes.divDashed, classes.divDashedRight)}>
              <div className={clsx(classes.dashedLine, classes.dashedLineRight)} />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default ProductSelection;
