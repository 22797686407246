import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'src/shared/constants';
import { getError, getProduct } from '../redux/quizSelectors';
import { getQuizAndProductInfo } from '../redux/quizOperations';
import useProductKey from './useProductKey';

export default ({ quizKey }) => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const hasError = useSelector(getError);
  const product = useSelector(getProduct);
  const { productKey } = useProductKey();

  useEffect(() => {
    if (hasError) push(routes.error.path);
  }, [push, dispatch, hasError]);

  useEffect(() => {
    if (!productKey) {
      push(routes.error.path);
    } else if (productKey) {
      dispatch(getQuizAndProductInfo({ productKey, quizKey }));
    }
  }, [dispatch, push, location.pathname, productKey, quizKey]);

  return { product: { ...product, productKey } };
};
