import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import configureStore, { history } from 'src/store';
import { createTheme } from 'src/shared/theme';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { OidcProvider } from 'redux-oidc';
import ReactGA from 'react-ga';
import ErrorBoundary from 'src/shared/components/ErrorBoundary.ig';
import { authManager } from 'src/features/auth/authManager';
import AuthAppBar from 'src/features/auth/views/AuthAppBar';
import { WhatsBtn } from 'src/shared/components';
import LoaderPage from 'src/features/loader/views/LoaderPage';
import Router from './Router';

const store = configureStore();

const App = () => {
  const theme = createTheme();

  useEffect(() => {
    if (!window.env.GOOGLE_KEY) return;

    ReactGA.initialize(window.env.GOOGLE_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <OidcProvider userManager={authManager.getUserManager()} store={store}>
              <>
                <LoaderPage />
                <Router AppBar={AuthAppBar} />
                <WhatsBtn />
              </>
            </OidcProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
