import { INITIAL_STATE as quoteInitialState } from 'src/features/quote/redux/quoteSlice';

const loadQuote = () => {
  try {
    const serializedQuote = localStorage.getItem('quote');
    if (serializedQuote === null) return undefined;

    const quote = JSON.parse(serializedQuote);

    return { quote: { ...quoteInitialState, ...quote } };
  } catch (error) {
    return undefined;
  }
};
export default loadQuote;
