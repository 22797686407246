import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar } from 'src/shared/components';
import { routes } from 'src/shared/constants';
import { authManager } from 'src/features/auth/authManager';
import { authActions } from 'src/features/auth/redux';
import { authSelector } from 'src/features/auth/redux/authSlice';
import { loaderActions } from 'src/features/loader/redux';
import { useGoogleAnalytics } from 'src/shared/hooks';

const AuthAppBar = () => {
  const dispatch = useDispatch();
  const isUserLogged = useSelector(authSelector.isUserLogged);
  const { event } = useGoogleAnalytics();

  const userLogout = () => {
    const userManager = authManager.getUserManager();
    dispatch(authActions.setLoggingOut(true));
    dispatch(loaderActions.start('Logout'));
    userManager.signoutRedirect({ state: { callbackUrl: routes.home.path } });

    event('Account', 'Logout');
  };

  return <AppBar showUserInfo={isUserLogged} userLogout={userLogout} />;
};

export default AuthAppBar;
