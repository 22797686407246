import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Checkbox, MessageBox, TermModal } from 'src/shared/components';
import { useAcceptProposal } from '../hooks';
import ProposalIssuanceTerms from './ProposalIssuanceTerms';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 16,
  },
  switch: {
    marginTop: '-3px',
    padding: 3,
  },
  termsText: {
    display: 'inline',
    fontSize: 14,
  },
  orangeText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const ProposalAcceptTerms = () => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  const {
    isProposalAccepted,
    proposalCanBeAccepted,
    acceptProposal,
    isErrorModalOpen,
    ackAcceptError,
  } = useAcceptProposal();

  const handleAccept = async (checked) => {
    if (!checked) return;
    setOpen(true);
  };

  const handleTermsClick = () => {
    setOpen(true);
  };

  return (
    <>
      <MessageBox
        opened={isErrorModalOpen}
        type="error"
        handleClose={ackAcceptError}
        handleSecondary={ackAcceptError}
        labelSecondary="Fechar"
        title="Erro ao aceitar a proposta"
        text="Ocorreu um erro ao aceitar a proposta. Por favor, tente mais tarde ou entre em contato em um dos canais de comunicação."
      />
      <TermModal
        open={isOpen}
        setOpen={setOpen}
        showAcceptButton={!isProposalAccepted}
        acceptTerms={acceptProposal}
        title="Termos e condições para emissão da apólice de seguro"
        subTitle="Prezado usuário, é muito importante a leitura atenta destes Termos no ato da emissão, pois ao emitir a apólice, você estará aceitando as nossas regras."
      >
        <ProposalIssuanceTerms />
      </TermModal>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Checkbox
            checked={isProposalAccepted}
            onChange={handleAccept}
            className={classes.switch}
            disabled={!proposalCanBeAccepted}
          />
          <Typography className={classes.termsText}>
            Li e aceito os{' '}
            <span onClick={handleTermsClick} aria-hidden="true" className={classes.orangeText}>
              termos e condições para emissão da apólice.
            </span>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalAcceptTerms;
