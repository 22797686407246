import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useUtils from 'src/shared/hooks/useUtils';
import { useStyles } from './styles';

const QuoteDetailsCard = ({ data }) => {
  const classes = useStyles();
  const { formatMoney, getTotalDaysDifference, formatUtcDate } = useUtils();

  function addAmountInsured() {
    let soma = 0;
    data.coverages.forEach((coverage) => {
      coverage.insuredObjects.forEach((obj) => {
        soma += obj.insuredAmount;
      });
    });

    return soma;
  }

  function getRoleParticipant(value) {
    switch (value) {
      case 'Beneficiary':
        return 'Beneficiário';
      case 'Insured':
        return 'Segurado';
      case 'PolicyHolder':
        return 'Tomador';
      default:
        return 'Outros';
    }
  }

  function formatPaymentType(value) {
    switch (value) {
      case 'Boleto':
        return 'Boleto';
      case 'Invoice':
        return 'Fatura';
      case 'CreditCardCheckout':
        return 'Crédito';
      case 'Ted':
        return 'Ted';
      case 'Debit':
        return 'Dédito';
      case 'Pix':
        return 'Pix';
      case 'CreditCardRecurrence':
        return 'Crédito';
      case 'Liquidation':
        return 'Liquidação';
      default:
        return 'Forma de pagamento';
    }
  }

  function formatToPercentage(value) {
    const percentage = value * 100;
    const percentageFormatted =
      percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1);
    return `${percentageFormatted}%`;
  }

  function hasBroker(commissionedAgents) {
    return commissionedAgents.some((agent) => agent.role === 'Broker');
  }

  function hasPolicyOwner(commissionedAgents) {
    return commissionedAgents.some((agent) => agent.role === 'PolicyOwner');
  }

  return (
    <Paper className={classes.card}>
      <Typography className={classes.cardTitle}>Detalhes da cotação</Typography>
      <Box className={classes.boxDetailsQuote}>
        <Box className={classes.boxValue}>
          <Typography className={classes.label}>Nº da cotação:</Typography>
          <Typography className={classes.value}>{data?.quoteSequential}</Typography>
        </Box>
        {data?.grossPremium >= 0 && (
          <Box className={classes.boxValue}>
            <Typography className={classes.label}>Prêmio:</Typography>
            <Typography className={classes.value}>{formatMoney(data.grossPremium)}</Typography>
          </Box>
        )}
        {!!data?.financialAmount && data?.financialAmount < 0 && (
          <Box className={classes.boxValue}>
            <Typography className={classes.label}>Restituição:</Typography>
            <Typography className={classes.value}>
              {formatMoney(data?.financialAmount * -1)}
            </Typography>
          </Box>
        )}
        {data?.coverages && data?.coverages?.length > 0 && (
          <Box className={classes.boxValue}>
            <Typography className={classes.label}>I.S:</Typography>
            <Typography className={classes.value}>{formatMoney(addAmountInsured())}</Typography>
          </Box>
        )}
        {data?.documentPeriodStart && data?.documentPeriodEnd && (
          <Box className={classes.boxValue}>
            <Typography className={classes.label}>Vigência:</Typography>
            <Typography className={classes.value}>
              {`${formatUtcDate(data?.documentPeriodStart)} à ${formatUtcDate(
                data?.documentPeriodEnd
              )} (${getTotalDaysDifference(data.documentPeriodStart, data.documentPeriodEnd)})`}
            </Typography>
          </Box>
        )}
        {data?.paymentConditions && data?.grossPremium > 0 && (
          <Box className={classes.boxValue}>
            <Typography className={classes.label}>
              {data?.paymentConditions?.paymentType &&
              data?.paymentConditions?.firstInstallmentPaymentType
                ? 'Formas de pagamento:'
                : 'Forma de pagamento:'}
            </Typography>
            <Typography className={classes.value}>
              {data?.paymentConditions?.firstInstallmentPaymentType &&
                `${formatPaymentType(data?.paymentConditions?.firstInstallmentPaymentType) 
                  } e `}{' '}
              {formatPaymentType(data?.paymentConditions?.paymentType)}
            </Typography>
          </Box>
        )}
      </Box>
      {data?.participants && data?.participants?.length > 0 && (
        <Box className={classes.boxList}>
          <Typography className={classes.subTitle}>Participantes</Typography>
          <Box className={classes.list}>
            {data.participants.map((participant, index) => (
              <Box key={index} className={classes.boxValue}>
                <Typography className={classes.label}>
                  {' '}
                  {getRoleParticipant(participant.role)}:
                </Typography>
                <Typography className={classes.value}>{participant.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {data?.commissionedAgents &&
        data?.commissionedAgents?.length > 0 &&
        (hasBroker(data?.commissionedAgents) || hasPolicyOwner(data?.commissionedAgents)) && (
          <Box className={classes.boxList}>
            <Typography className={classes.subTitle}>Comissões</Typography>
            <Box className={classes.list}>
              {data.commissionedAgents
                .filter((agent) => agent.role === 'Broker' || agent.role === 'PolicyOwner')
                .map((agent, index) => (
                  <Box key={index} className={classes.boxValue}>
                    <Typography className={classes.label}> {agent.name}:</Typography>
                    <Typography className={classes.value}>
                      {formatToPercentage(agent.commissionPercentage)}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        )}
    </Paper>
  );
};

QuoteDetailsCard.propTypes = {
  data: PropTypes.shape({
    quoteSequential: PropTypes.number.isRequired,
    documentPeriodStart: PropTypes.string,
    documentPeriodEnd: PropTypes.string,
    grossPremium: PropTypes.number.isRequired,
    financialAmount: PropTypes.number,
    paymentConditions: PropTypes.shape({
      paymentType: PropTypes.string.isRequired,
      installments: PropTypes.number.isRequired,
      firstInstallmentPaymentType: PropTypes.string,
    }),
    coverages: PropTypes.arrayOf(
      PropTypes.shape({
        insuredObjects: PropTypes.arrayOf(
          PropTypes.shape({
            insuredAmount: PropTypes.number.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    commissionedAgents: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        commissionPercentage: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default QuoteDetailsCard;
