import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getProductKeyByPathname } from 'src/shared/helpers';

export default () => {
  const { location } = useHistory();
  const productKey = useMemo(() => getProductKeyByPathname(location.pathname), [location.pathname]);

  return { productKey };
};
