import { createAsyncThunk } from '@reduxjs/toolkit';
import { loaderActions } from 'src/features/loader/redux';
import quoteManager from '../quoteManager';

export const getQuoteById = createAsyncThunk(
  'quote/GET_QUOTE_BY_ID',
  async ({ quoteId, productKey }, { dispatch }) => {
    dispatch(loaderActions.start('getQuoteById'));
    try {
      const quote = await quoteManager.getQuoteById({ quoteId, productKey });
      return { ...quote, quoteId, productKey };
    } finally {
      dispatch(loaderActions.stop('getQuoteById'));
    }
  }
);

export const updateQuote = createAsyncThunk(
  'quote/UPDATE_QUOTE',
  async ({ quoteId, productKey, insuredAmount, additionalCoverageIncluded }, { dispatch }) => {
    dispatch(loaderActions.start('updateQuote'));
    try {
      return await quoteManager.updateQuote({
        quoteId,
        productKey,
        insuredAmount,
        additionalCoverageIncluded,
      });
    } finally {
      dispatch(loaderActions.stop('updateQuote'));
    }
  }
);

export const createProposal = createAsyncThunk(
  'quote/CREATE_PROPOSAL',
  async ({ productKey, quoteId }, { dispatch }) => {
    dispatch(loaderActions.start('createProposal'));
    try {
      return await quoteManager.createProposal({ productKey, quoteId });
    } finally {
      dispatch(loaderActions.stop('createProposal'));
    }
  }
);
