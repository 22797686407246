import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 24,
  },
  header: {
    width: '100%',
    justifyContent: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: 24,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.text.primary,
    width: '100%',
  },
  text: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  boxCard: {
    width: '100%',
  },
  boxObservation: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    gap: '16px',
  },
  titleObservation: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  textObservation: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  emailLink: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  boxFooter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    gap: '16px',
    marginBlock: '24px',
  },
  titleFooter: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  textFooter: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  button: {
    minWidth: '200px',
  },
  boxButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
}));
