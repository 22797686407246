import React from 'react';
import PropTypes from 'prop-types';
import { isValid } from '@fnando/cpf';
import { BaseInput, BaseNumberFormat } from '../BaseInput';

const CPF_SIZE = 11;

const NumberFormat = (props) => (
  <BaseNumberFormat
    format="###.###.###-##"
    mask="_"
    isAllowed={({ value }) => value.length <= CPF_SIZE}
    {...props}
  />
);

NumberFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CpfInput = (props) => (
  <BaseInput inputComponent={NumberFormat} isValid={isValid} {...props} />
);

CpfInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

CpfInput.defaultProps = {
  errorMessage: 'CPF inválido',
  onBlur: () => {},
  label: 'CPF',
  placeholder: '',
  disabled: false,
  required: false,
};

export default CpfInput;
