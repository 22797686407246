import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Typography, MobileStepper, Grid, makeStyles } from '@material-ui/core';
import { Approved } from 'src/shared/assets/svg';
import { productComplement, productNames, quoteStatus } from 'src/shared/constants';
import { useQuizSize, useGoogleAnalytics } from 'src/shared/hooks';
import { getQuote, getCreateQuoteFulfilled } from '../redux/quoteSelector';
import useAccessByQuoteValidator from '../hooks/useAccessByQuoteValidator';
import { useQuoteLogin } from '../hooks';

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: 40,
  },
  product: {
    textAlign: 'center',
    fontSize: '0.7rem',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  productName: {
    marginTop: 5,
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 22,
    color: theme.palette.text.darkBlue,
    textAlign: 'center',
    marginTop: 35,
  },
  image: {
    width: 150,
    marginTop: 15,
  },
  alignCenter: {
    textAlign: 'center',
  },
  btnContainer: {
    marginTop: 20,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  button: {
    width: 150,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 25,
    },
  },
  textInfo: {
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    marginTop: 30,
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  rootStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    margin: '0 15px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const QuoteApproved = () => {
  const classes = useStyles();
  const { push, location } = useHistory();
  const user = useSelector(({ oidc }) => oidc.user);
  const size = useQuizSize();
  const quote = useSelector(getQuote);
  const accessByQuoteValidator = useAccessByQuoteValidator(push);
  const { event } = useGoogleAnalytics();
  const { redirectToLogin } = useQuoteLogin();
  const wasQuoteCreated = useSelector(getCreateQuoteFulfilled);

  useEffect(() => {
    accessByQuoteValidator(quote, location.pathname, quoteStatus.approved);
  }, [quote, location.pathname, accessByQuoteValidator]);

  useEffect(() => {
    if (wasQuoteCreated) {
      event('Quote', 'QuoteApproved', `${productNames[quote.productKey]}`);
    }
  }, [wasQuoteCreated, event, quote.productKey]);

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.stepper}>
        <MobileStepper
          steps={size}
          variant="dots"
          position="static"
          className={classes.root}
          classes={{ dot: classes.dot, root: classes.rootStep }}
          data-testid="finished-stepper"
        />
      </Grid>

      <Grid item xs={12} className={classes.productName}>
        <Typography data-testid="title" className={clsx(classes.product, classes.productName)}>
          {productNames[quote.productKey]}
        </Typography>
      </Grid>
      {productComplement[quote.productKey] && (
        <Grid item xs={12}>
          <Typography data-testid="productName" className={classes.product}>
            {productComplement[quote.productKey]}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography className={classes.title}>Seu crédito foi aprovado!</Typography>
      </Grid>
      <Grid item xs={12} className={classes.alignCenter}>
        <img src={Approved} alt="Approved" className={classes.image} />
      </Grid>
      <Grid item xs={10} md={4} xl={3} className={classes.alignCenter}>
        {user == null ? (
          <Typography className={classes.textInfo}>
            Cadastre-se ou faça login para visualizar sua cotação.
            <br /> Após essa etapa, daremos sequência à análise do risco!
          </Typography>
        ) : null}
      </Grid>
      <Grid container justify="center" className={classes.btnContainer}>
        {user == null ? (
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => push('/cadastro')}
              className={classes.button}
            >
              Cadastre-se
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={redirectToLogin}
            className={classes.button}
          >
            {user == null ? 'Login' : 'Continuar'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuoteApproved;
