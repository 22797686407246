import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiSwitch from '@material-ui/core/Switch';

const Switch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 16,
    padding: 0,
    overflow: 'inherit',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
  focusVisible: {},
}))(({ classes, onChange, ...props }) => {
  const handleChange = (e) => {
    onChange(e.target.checked, e.target.checked);
  };
  return (
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      onChange={handleChange}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default Switch;
