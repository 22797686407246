import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Avatar, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  avatar: {
    [theme.breakpoints.down('sm')]: {
      width: 56,
      height: 56,
    },
    [theme.breakpoints.up('sm')]: {
      width: 70,
      height: 70,
    },
  },
  title: {
    maxWidth: 600,
    marginLeft: 20,
    alignItems: 'center',
    backgroundColor: '#D7E1E9',
    padding: '15px 20px',
    borderRadius: '30px 30px 30px 0',
    fontWeight: 500,
    fontSize: 18,
    fontFamily: '"Open Sans", sans-serif',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      maxWidth: 210,
      marginLeft: 5,
    },
  },
  children: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 50,
    },
  },
}));

const Question = ({ avatar, question }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="center">
      <Avatar alt="Avatar" src={avatar} className={classes.avatar} />
      <div className={classes.title}>
        <Typography data-testid="title" className={classes.productName}>
          {question}
        </Typography>
      </div>
    </Grid>
  );
};

Question.propTypes = {
  avatar: PropTypes.string.isRequired,
  question: PropTypes.string,
};

Question.defaultProps = {
  question: {},
};

export default Question;
