import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    bottom: 0,
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    color: '#FFF',
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  link: {
    color: '#FFF',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#232323',
      transition: 'color 0.2s ease-in',
    },
  },
}));

const SmallFooter = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.info}>
          Pottencial Seguradora S.A. - CNPJ: 11.699.534/0001-74 - SUSEP: 03069 - SAC:
          (31) 2121-7777 | 0800 606 7688 - Ouvidoria: 0800 200 1080 -{" "}
          <a
            href="https://static.pottencial.com.br/sitefiles/Governanca/Politica-Governanca-Privacidade-Dados.pdf"
            title="Ir para a página de política de privacidade"
            aria-label="Ir para a página de política de privacidade"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.link, classes.info)}
          >
            Política de Privacidade
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SmallFooter;
