import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Paper, Button, makeStyles } from '@material-ui/core';
import { formatCnpj, formatToBrlMoney } from 'src/shared/helpers';
import {
  fullProductNames,
  participantRoles,
  productNames,
  productComplement,
} from 'src/shared/constants';
import { useFormattedDate, useGoogleAnalytics } from 'src/shared/hooks';
import { LabeledText, LabeledTextAsync } from 'src/shared/components';
import { getParticipantByRole } from '../redux/proposalSelector';
import * as proposalOperations from "../redux/proposalOperations";
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 50,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  cardInfo: {
    fontSize: '1rem',
    padding: '8px 0',
  },
  cardTitle: {
    fontSize: 20,
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: 700,
    marginBottom: 10,
  },
  divButton: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    marginTop: 10,
    height: 40,
    width: '100%',
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: '#FFF',
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '20px',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
  anchor: {
    textDecoration: 'none',
  },
  orangeText: {
    color: theme.palette.primary.main,
  },
  additionalCoverageText: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
  },
}));

const CustomInput = ({ label: _label, value, complementValue, isLoading }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardInfo}>
      <LabeledTextAsync
        isLoading={isLoading}
        label={_label}
        fallback={(label) => (
          <LabeledText
            label={label}
            value={value}
            complementValue={complementValue}
            className={classes.cardInfo}
          />
        )}
      />
    </div>
  );
};

CustomInput.propTypes = {
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  value: PropTypes.string,
  complementValue: PropTypes.string,
};

CustomInput.defaultProps = {
  value: '',
  complementValue: null,
};

const ProposalDetailsCard = ({ proposal, isProposalLoading }) => {
  const classes = useStyles();
  const policyHolder = useSelector(getParticipantByRole(participantRoles.policyHolder));
  const insured = useSelector(getParticipantByRole(participantRoles.insured));
  const policyPeriodStart = useFormattedDate(proposal.policyPeriodStart);
  const policyPeriodEnd = useFormattedDate(proposal.policyPeriodEnd);
  const { event } = useGoogleAnalytics();
  const dispatch = useDispatch();
  const [draftDocumentResult, setDraftDocumentResult] = useState();

  const eventDownloadProposal = () => {
    event('Download', 'DownloadProposal', `${productNames[proposal.productKey]}`);
  };

  const eventDownloadDraft = () => {
    if (draftDocumentResult) {
      showDraftProposal(draftDocumentResult);
    } else {
      dispatch(proposalOperations.getProposalDraft({ proposalId: proposal.proposalId, productKey: proposal.productKey }))
        .then(response => {
          setDraftDocumentResult(response);
          showDraftProposal(response);
        });
    }
  };

  const showDraftProposal = (draft) => {
    const w = window.open('', '_blank');
    w.document.title = draft.payload.fileDownloadName;
    w.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
        draft.payload.fileContents
      )}'></iframe>`
    );
  };

  return (
    <Paper elevation={3} className={clsx(classes.card, classes.detailsCard)}>
      <Typography className={classes.cardTitle}>
        Proposta <span className={classes.orangeText}>{proposal.proposalNumber}</span>
      </Typography>
      <CustomInput
        label="CNPJ do cliente"
        value={formatCnpj(policyHolder.documentNumber)}
        isLoading={isProposalLoading}
      />
      <CustomInput label="Cliente" value={policyHolder.name} isLoading={isProposalLoading} />
      <CustomInput
        label="CNPJ do segurado"
        value={formatCnpj(insured.documentNumber)}
        isLoading={isProposalLoading}
      />
      <CustomInput label="Segurado" value={insured.name} isLoading={isProposalLoading} />
      <CustomInput
        label="CNPJ da Corretora"
        value={formatCnpj(proposal.brokerDocumentNumber)}
        isLoading={isProposalLoading}
      />
      <CustomInput label="Nome da Corretora" value={proposal.brokerName} isLoading={isProposalLoading} />
      <CustomInput
        label="Tipo de seguro"
        value={fullProductNames[proposal.productKey]}
        complementValue={productComplement[proposal.productKey]}
        isLoading={isProposalLoading}
      />
      <CustomInput
        label="Vigência"
        value={`${policyPeriodStart} até ${policyPeriodEnd}`}
        isLoading={isProposalLoading}
      />
      <CustomInput
        label="Valor da garantia"
        value={formatToBrlMoney(proposal.insuredAmount)}
        isLoading={isProposalLoading}
      />

      {proposal.hasAdditionalCoverage ? (
        <Typography className={clsx(classes.cardInfo, classes.additionalCoverageText)}>
          Possui cobertura adicional trabalhista
        </Typography>
      ) : null}

      <div className={classes.divButton}>
        <a className={classes.anchor} href={proposal.documentUrl} target="_blank" download="proposta.pdf" rel="noopener noreferrer">
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={eventDownloadProposal}
          >
            Download da Proposta
          </Button>
        </a>
      </div>

      <div className={classes.divButton}>
        <div className={classes.anchor}>
          <Button
            color="primary"
            variant="contained"
            className={`${classes.button} ${classes.anchor}`}
            onClick={eventDownloadDraft}
          >
            Download da Minuta
          </Button>
        </div>
      </div>
    </Paper>
  );
};

ProposalDetailsCard.propTypes = {
  proposal: PropTypes.object,
  isProposalLoading: PropTypes.bool.isRequired,
};

ProposalDetailsCard.defaultProps = {
  proposal: {},
};

export default ProposalDetailsCard;
