import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as quoteOperations from 'src/features/quote/redux/quoteOperations';
import { fetchStatus, maxInsuredAmount, routes, underAnalysis } from 'src/shared/constants';
import { getProductKeyByPathname } from 'src/shared/helpers';

export default () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const quote = useSelector((state) => state.quote);
  const [insuredAmountValue, setInsuredAmountValue] = useState();
  const productKey = getProductKeyByPathname(location.pathname);

  const updateStatus = quote.fetchStatus.updateQuote;
  const hasUpdateError = updateStatus === fetchStatus.rejected;
  const hasForbiddenError = updateStatus === fetchStatus.forbidden;

  const getStatus = quote.fetchStatus.getQuoteById;
  const isQuoteLoading = useMemo(() => getStatus !== fetchStatus.fulfilled, [getStatus]);

  useEffect(() => {
    if (hasForbiddenError) push(`${routes[productKey].path}${underAnalysis}`);
    else if (hasUpdateError) push(routes.error.path);
  }, [push, hasForbiddenError, hasUpdateError, productKey]);

  useEffect(() => {
    setInsuredAmountValue(quote.insuredAmount);
  }, [quote]);

  const updateQuote = () => {
    if (
      insuredAmountValue !== quote.insuredAmount &&
      insuredAmountValue > 0 &&
      insuredAmountValue <= maxInsuredAmount
    ) {
      dispatch(
        quoteOperations.updateQuote({
          quoteId: quote.quoteId,
          productKey,
          insuredAmount: insuredAmountValue,
          additionalCoverageIncluded: quote.additionalCoverageIncluded,
        })
      );
    }
  };

  return { isQuoteLoading, updateQuote, insuredAmountValue, setInsuredAmountValue };
};
