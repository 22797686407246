import { createSlice } from '@reduxjs/toolkit';
import { fetchStatus, proposalStatus } from 'src/shared/constants';
import * as proposalOperations from './proposalOperations';

export const INITIAL_STATE = {
  proposalId: '',
  productName: '',
  proposalNumber: '',
  participants: [],
  hasAdditionalCoverage: false,
  policyPeriodStart: '',
  policyPeriodEnd: '',
  documentUrl: '',
  documentName: '',
  status: null,
  policyUrl: null,
  fetchStatus: {
    getProposalById: fetchStatus.notFetched,
    acceptProposal: fetchStatus.notFetched,
    issuePolicy: fetchStatus.notFetched,
    getProposalDraft: fetchStatus.notFetched,
  },
};

const proposalSlice = createSlice({
  name: 'proposal',
  initialState: INITIAL_STATE,
  reducers: {
    setProposal(state, action) {
      return { ...state, ...action.payload };
    },
    cleanProposal() {
      return INITIAL_STATE;
    },
    cleanAcceptedFetchStatus(state) {
      state.fetchStatus.acceptProposal = fetchStatus.notFetched;
    },
    cleanErrors(state) {
      return { ...state, fetchStatus: INITIAL_STATE.fetchStatus };
    },
    cleanIssuePolicyError(state) {
      state.fetchStatus.issuePolicy = fetchStatus.notFetched;
    },
  },
  extraReducers: {
    [proposalOperations.getProposalById.fulfilled]: (state, action) => {
      const newFetchStatus = { ...state.fetchStatus, getProposalById: fetchStatus.fulfilled };
      return { ...state, ...action.payload, fetchStatus: newFetchStatus };
    },
    [proposalOperations.getProposalById.rejected]: (state, action) => {
      state.fetchStatus.getProposalById = fetchStatus.rejected;
      state.error = action.error;
    },
    [proposalOperations.acceptProposal.fulfilled]: (state) => {
      const newFetchStatus = { ...state.fetchStatus, acceptProposal: fetchStatus.fulfilled };
      return { ...state, status: proposalStatus.accepted, fetchStatus: newFetchStatus };
    },
    [proposalOperations.acceptProposal.rejected]: (state) => {
      state.fetchStatus.acceptProposal = fetchStatus.rejected;
    },
    [proposalOperations.issuePolicy.fulfilled]: (state, action) => {
      state.fetchStatus.issuePolicy = fetchStatus.fulfilled;
      state.policyUrl = action.payload;
    },
    [proposalOperations.issuePolicy.rejected]: (state) => {
      state.fetchStatus.issuePolicy = fetchStatus.rejected;
    },
    [proposalOperations.getProposalDraft.fulfilled]: (state, action)=> {
      const newFetchStatus = { ...state.fetchStatus, getProposalDraft: fetchStatus.fulfilled };
      return { ...state, ...action.payload, fetchStatus: newFetchStatus };
    },
    [proposalOperations.getProposalDraft.rejected]: (state, action) => {
      state.fetchStatus.getProposalDraft = fetchStatus.rejected;
      state.error = action.error;
    },
  },
});

export default proposalSlice;
