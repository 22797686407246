import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { SignoutCallbackComponent } from 'redux-oidc';
import { routes } from 'src/shared/constants';
import { authManager } from '../authManager';
import { authActions } from '../redux';

const SignoutCallbackPage = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const successCallback = () => {
    dispatch(authActions.setLoggingOut(false));
    push(routes.home.path);
  };

  return (
    <SignoutCallbackComponent
      successCallback={successCallback}
      userManager={authManager.getUserManager()}
    >
      <Grid container style={{ padding: '25%' }} justify="center" alignItems="center">
        <Grid item style={{ padding: '20px 0' }}>
          <Typography variant="body1">Um momento...</Typography>
        </Grid>
      </Grid>
    </SignoutCallbackComponent>
  );
};

export default SignoutCallbackPage;
