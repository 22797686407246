import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Input, FormHelperText } from '@material-ui/core';
import HelperText from './HelperText';
import Tooltip from '../Tooltip/Tooltip';

const validateRange = (min, max, value) => {
  const lowerBoundaryIsValid = _.isNumber(min) && value >= min;
  const upperBoundaryIsValid = _.isNumber(max) && value <= max;

  if (min && !lowerBoundaryIsValid) {
    return { valid: false, boundary: 'min' };
  }

  if (max && !upperBoundaryIsValid) {
    return { valid: false, boundary: 'max' };
  }

  return { valid: true, boundary: null };
};

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, value, min, max, ...other } = props;

  return (
    <NumberFormat
      data-testid="number-format"
      value={value || ''}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      {...other}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
};

NumberFormatCustom.defaultProps = {
  value: 0,
  min: null,
  max: null,
  decimalScale: 0,
  fixedDecimalScale: false,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
    fontWeight: 400,
    marginTop: 16,
    marginBottom: 22,
  },
  helperText: {
    marginBottom: -22,
  },
  tooltipIcon: {
    cursor: 'pointer',
    width: 22,
  },
  tooltip: {
    width: 200,
    backgroundColor: theme.palette.text.darkBlue,
    fontSize: 12,
    fontFamily: 'Poppins',
    padding: 15,
    fontWeight: 300,
    textAlign: 'center',
  },
  tooltipArrow: {
    color: theme.palette.text.darkBlue,
  },
}));

export default function NumberInput({
  value,
  onChange,
  onBlur,
  placeholder,
  showInputLimits,
  disabled,
  numberFormatProps,
  min,
  max,
  classes: _classes,
  tooltip,
  ...props
}) {
  const classes = useStyles();
  const [error, setError] = useState('');
  const hasError = !!error;
  return (
    <div className={classes.container}>
      <FormControl className={clsx(classes.formControl, _classes.formControl)}>
        <Input
          error={hasError}
          className={classes.input}
          placeholder={placeholder || ''}
          value={value}
          onChange={(e) => {
            const validation = validateRange(min, max, Number(e.target.value));
            onChange(Number(e.target.value), validation.valid);
            setError('');
          }}
          onBlur={() => {
            const validation = validateRange(min, max, value);
            setError(validation.boundary);
            if (onBlur) onBlur();
          }}
          inputComponent={NumberFormatCustom}
          inputProps={{ ...numberFormatProps }}
          disabled={disabled}
          endAdornment={tooltip !== null ? <Tooltip title={tooltip} /> : <></>}
          {...props}
        />
        {hasError && (
          <FormHelperText error={hasError} className={classes.helperText}>
            <HelperText error={error} {...numberFormatProps} min={min} max={max} />
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  showInputLimits: PropTypes.bool,
  disabled: PropTypes.bool,
  numberFormatProps: PropTypes.object,
  classes: PropTypes.object,
  tooltip: PropTypes.string,
};

NumberInput.defaultProps = {
  value: null,
  onBlur: () => {},
  placeholder: null,
  min: null,
  max: null,
  showInputLimits: false,
  disabled: false,
  numberFormatProps: {
    prefix: '',
    decimalScale: 0,
    fixedDecimalScale: false,
  },
  classes: {
    formControl: {},
  },
  tooltip: null,
};
