import { fetchStatus } from 'src/shared/constants';

export const getProposal = ({ proposal }) => proposal;
export const getAcceptProposalFetchError = ({ proposal }) =>
  proposal.fetchStatus.acceptProposal === fetchStatus.rejected;
export const getAcceptProposalFulfilled = ({ proposal }) =>
  proposal.fetchStatus.acceptProposal === fetchStatus.fulfilled;
export const getProposalFetchError = ({ proposal }) =>
  proposal.fetchStatus.getProposalById === fetchStatus.rejected;
export const getParticipantByRole = (role) => ({ proposal }) => {
  if (!proposal.participants.length) return {};
  return proposal.participants.find((p) => p.role === role);
};
export const getProposalRejected = ({ quiz }) =>
  quiz.proposal.fetchStatus === (fetchStatus.rejected || fetchStatus.forbidden);
export const getDraft = ({ draft }) => draft;