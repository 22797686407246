import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  rootStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    margin: '0 15px',
    backgroundColor: '#D7E1E9',
  },
  dotActive: {
    backgroundColor: '#F26522',
  },
});

const Stepper = (props) => {
  const classes = useStyles();
  return (
    <MobileStepper
      data-testid="mobile-stepper"
      variant="dots"
      position="static"
      className={classes.root}
      classes={{ dot: classes.dot, root: classes.rootStep, dotActive: classes.dotActive }}
      {...props}
    />
  );
};

Stepper.propTypes = {
  steps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default Stepper;
