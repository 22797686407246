import { createAsyncThunk } from '@reduxjs/toolkit';
import { loaderActions } from 'src/features/loader/redux';
import proposalManager from '../proposalManager';

export const getProposalById = createAsyncThunk(
  'proposal/GET_PROPOSAL_BY_ID',
  async ({ proposalId, productKey }, { dispatch }) => {
    dispatch(loaderActions.start('getProposalById'));
    try {
      const proposal = await proposalManager.getProposalById({ proposalId, productKey });
      return { ...proposal, proposalId, productKey };
    } finally {
      dispatch(loaderActions.stop('getProposalById'));
    }
  }
);

export const acceptProposal = createAsyncThunk(
  'proposal/ACCEPT_PROPOSAL',
  async ({ proposalId, productKey }, { dispatch }) => {
    dispatch(loaderActions.start('acceptProposal'));
    try {
      await proposalManager.acceptProposal({ proposalId, productKey });
    } finally {
      dispatch(loaderActions.stop('acceptProposal'));
    }
  }
);

export const issuePolicy = createAsyncThunk(
  'proposal/ISSUE_POLICY',
  async ({ proposalId, productKey }, { dispatch }) => {
    dispatch(loaderActions.start('issuePolicy'));
    try {
      return await proposalManager.issuePolicy({ proposalId, productKey });
    } finally {
      dispatch(loaderActions.stop('issuePolicy'));
    }
  }
);

export const getProposalDraft = createAsyncThunk(
  'proposal/GET_PROPOSAL_DRAFT',
  async ({ proposalId, productKey }, { dispatch }) => {
    dispatch(loaderActions.start('getProposalDraft'));
    try {
      const proposalDraft = await proposalManager.getProposalDraft({ proposalId, productKey });
      return { ...proposalDraft, proposalId, productKey };
    } 
    finally {
      dispatch(loaderActions.stop('getProposalDraft'));
    }
  }
);
