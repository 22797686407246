import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routes, productNames } from 'src/shared/constants';
import { Button, Typography, MobileStepper, Grid, makeStyles } from '@material-ui/core';
import { UnderEvaluation } from 'src/shared/assets/svg';
import { useQuizSize } from 'src/shared/hooks';
import { useProductKey } from '../hooks';

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: 60,
  },
  productName: {
    marginTop: 5,
    textAlign: 'center',
    fontSize: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 22,
    color: theme.palette.text.darkBlue,
    textAlign: 'center',
    marginTop: 35,
    [theme.breakpoints.down('xs')]: {
      fontSize: '5vw',
    },
  },
  image: {
    width: 150,
    marginTop: 25,
  },
  alignCenter: {
    textAlign: 'center',
  },
  btnContainer: {
    marginTop: 10,
    textAlign: 'center',
  },
  button: {
    width: 200,
    marginTop: 20,
  },
  textInfo: {
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    marginTop: 60,
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  rootStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    margin: '0 15px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const InsuredUnauthorized = () => {
  const classes = useStyles();
  const size = useQuizSize();
  const { push } = useHistory();
  const { productKey } = useProductKey();

  const returnToHome = useCallback(() => {
    push(routes.home.path);
  }, [push]);

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.stepper}>
        <MobileStepper
          steps={size}
          variant="dots"
          position="static"
          className={classes.root}
          classes={{ dot: classes.dot, root: classes.rootStep }}
          data-testid="finished-stepper"
        />
      </Grid>

      <Grid item xs={12} className={classes.productName}>
        <Typography data-testid="title" className={classes.productName}>
          {productNames[productKey]}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.title}>Algo deu errado no processo!</Typography>
      </Grid>
      <Grid item xs={12} className={classes.alignCenter}>
        <img src={UnderEvaluation} alt="Under Evaluation" className={classes.image} />
      </Grid>
      <Grid item xs={10} md={4} lg={4} xl={3} className={classes.alignCenter}>
        <Typography className={classes.textInfo}>
          Não foi possível prosseguir com os dados fornecidos, envie um e-mail para
          <b>&nbsp;garantia.digital@pottencial.com.br</b>
          &nbsp;para mais informações.
        </Typography>
      </Grid>
      <Grid container justify="center" className={classes.btnContainer}>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={returnToHome}
            className={classes.button}
          >
            Voltar para o início
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

InsuredUnauthorized.propTypes = {};

export default InsuredUnauthorized;
