import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
  LogoFooter,
  IcoFacebook,
  IcoInstagram,
  IcoTwitter,
  IcoLinkedin,
  IcoPhone,
  IcoMarker,
} from 'src/shared/assets/svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    padding: '60px 50px',
    color: '#FFF',
    backgroundColor: '#F2F2F2',
    [theme.breakpoints.down('xs')]: {
      padding: '50px 30px',
    },
  },
  logo: {
    borderRadius: 5,
  },
  gridLogo: {
    maxWidth: 164,
  },
  linkSocial: {
    height: 30,
  },
  iconSocial: {
    width: 30,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 30,
    },
  },
  footerItems: {
    maxWidth: 1200,
    display: 'flex',
    justifyContent: 'center',
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: 5,
    cursor: 'pointer',
    minWidth: 42,
    height: 42,
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      transition: 'background-color 0.3s ease-in',
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  followText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 74,
    [theme.breakpoints.down('sm')]: {
      marginTop: 36,
    },
  },
  icon: {
    width: 15,
    marginRight: 8,
    marginBottom: -2,
  },
  addressItem: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  copyrightItem: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  link: {
    fontSize: 14,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      color: '#232323',
      transition: 'color 0.2s ease-in',
    },
  },
  info: {
    marginTop: 10,
  },
  gridLinks: {
    marginTop: theme.spacing(1),
    marginLeft: 150,
    [theme.breakpoints.down('md')]: {
      marginLeft: '8vw',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  const getYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Grid container className={classes.footer}>
      <Grid item xs={12} className={classes.footerItems}>
        <Grid container justify="space-between" direction="row">
          <Grid xs={12} md item className={clsx(classes.gridLogo)}
          >
            <a
              href="https://www.pottencial.com.br"
              title="Ir para a página principal"
              aria-label="Ir para a página principal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LogoFooter} className={classes.logo} alt="Logo - Pottencial Seguradora" />
            </a>

            <Typography className={classes.followText}>Nos siga:</Typography>

            <div className={classes.socialIcons}>
              <div className={classes.iconContainer}>
                <a
                  href="https://www.facebook.com/PottencialSeguradora"
                  title="Ir para o facebook"
                  aria-label="Ir para o Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkSocial}
                >
                  <img src={IcoFacebook} className={classes.iconSocial} alt="Ícone - Facebook" />
                </a>
              </div>
              <div className={classes.iconContainer}>
                <a
                  href="https://twitter.com/pottencial"
                  title="Ir para o twitter"
                  aria-label="Ir para o Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkSocial}
                >
                  <img src={IcoTwitter} className={classes.iconSocial} alt="Ícone - Facebook" />
                </a>
              </div>
              <div className={classes.iconContainer}>
                <a
                  href="https://instagram.com/pottencialseguradora"
                  title="Ir para o Instagram"
                  aria-label="Ir para o instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkSocial}
                >
                  <img src={IcoInstagram} className={classes.iconSocial} alt="Ícone - Facebook" />
                </a>
              </div>
              <div className={classes.iconContainer}>
                <a
                  href="https://www.linkedin.com/company/pottencial-seguradora-s-a"
                  title="Ir para o linkedin"
                  aria-label="Ir para o LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkSocial}
                >
                  <img src={IcoLinkedin} className={classes.iconSocial} alt="Ícone - Facebook" />
                </a>
              </div>
            </div>
          </Grid>

          <Grid xs={12} md item className={classes.gridLinks}>
            <Grid container>
              <Grid item xs={12} md={3} className={clsx(classes.item)}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography className={classes.title}>A Pottencial</Typography>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://www.pottencial.com.br/sobre-nos/"
                      title="Ir para a página Sobre"
                      aria-label="Ir para a página Sobre"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Sobre nós
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://www.pottencial.com.br/trabalhe-conosco/"
                      title="Ir para a página Trabalhe Conosco"
                      aria-label="Ir para a página Trabalhe Conosco"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Trabalhe Conosco
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://www.pottencial.com.br/certidoes-legais/"
                      title="Ir para a página de Certidões Legais"
                      aria-label="Ir para a página de Certidões Legais"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Certidões Legais
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://www.pottencial.com.br/ouvidoria/"
                      title="Ir para a página de Ouvidoria"
                      aria-label="Ir para a página de Ouvidoria"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Ouvidoria
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://pottencial.com.br/transparencia-e-privacidade/"
                      title="Ir para a página de Transparência e Privacidade"
                      aria-label="Ir para a página de Transparência e Privacidade"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Transparência e Privacidade
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://static.pottencial.com.br/sitefiles/Governanca/Politica-cookies-v001.pdf"
                      title="Ir para a página de política de cookies"
                      aria-label="Ir para a página de política de cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Política de Cookies
                    </a>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3} className={clsx(classes.item)}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography className={classes.title}>Recursos</Typography>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://www.pottencial.com.br/consultar-apolice/"
                      title="Ir para a página de Consultar Apólice"
                      aria-label="Ir para a página de Consultar Apólice"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Consultar Apólice
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://blog.pottencial.com.br/"
                      title="Ir para a página de Blog"
                      aria-label="Ir para a página de Blog"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Blog
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://glossario.pottencial.com.br/"
                      title="Ir para a página Glossário de Seguros"
                      aria-label="Ir para a página Glossário de Seguros"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Glossário de Seguros
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://www.pottencial.com.br/sinistro/"
                      title="Ir para a página de Sinistro"
                      aria-label="Ir para a página de Sinistro"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Sinistro
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://agiliza.pottencial.com.br/"
                      title="Ir para a página Manifesto Ágil"
                      aria-label="Ir para a página Manifesto Ágil"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Manifesto Ágil
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://developers.pottencial.com.br/api-portal/"
                      title="Ir para a página Área do Desenvolvedor"
                      aria-label="Ir para a página Área do Desenvolvedor"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Área do Desenvolvedor
                    </a>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3} className={clsx(classes.item)}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography className={classes.title}>Canais</Typography>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://www.pottencial.com.br/fale-conosco/"
                      title="Ir para a página de Contato"
                      aria-label="Ir para a página de Contato"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Fale Conosco
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://ethicspeakup.com.br/pottencial/"
                      title="Ir para a página"
                      aria-label="Ir para a página"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(classes.link, classes.info)}
                    >
                      Canal de Denúncias
                    </a>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3} className={clsx(classes.item)}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography className={classes.title}>SAC</Typography>
                  </Grid>

                  <Grid item>
                    <img src={IcoPhone} className={classes.icon} alt="Ícone Telefone" />
                    <a
                      href="tel:3121217777"
                      title="Fale por telefone"
                      aria-label="Fale por telefone"
                      className={clsx(classes.link, classes.info)}
                    >
                      (31) 2121-7777
                    </a>
                  </Grid>

                  <Grid item>
                    <img src={IcoPhone} className={classes.icon} alt="Ícone Telefone" />
                    <a
                      href="tel:08006067688"
                      title="Fale por telefone"
                      aria-label="Fale por telefone"
                      className={clsx(classes.link, classes.info)}
                    >
                      0800 606 7688
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.addressItem}>
              <img src={IcoMarker} className={classes.icon} alt="Ícone Telefone" />
              <a
                href="https://www.google.com/maps/place/Av.+Raja+Gab%C3%A1glia,+1143+-+Cidade+Jardim,+Belo+Horizonte+-+MG,+30380-103/@-19.9459454,-43.9592027,17z/data=!3m1!4b1!4m5!3m4!1s0xa6979c3e43171f:0x9d8eb8d9d2da614b!8m2!3d-19.9459505!4d-43.957014"
                title="Ir para o mapa"
                aria-label="Ir para o mapa"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Av. Raja Gabáglia, 1143, 19°Andar Luxemburgo, Belo Horizonte - MG
              </a>
            </div>

            <Typography className={classes.copyrightItem}>
              Pottencial Seguradora S.A. – CNPJ: 11.699.534/0001-74 – Susep: 03069   © Pottencial Seguradora, {getYear()}. Todos os direitos reservados.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
