import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loaderActions } from 'src/features/loader/redux';
import { fetchStatus } from 'src/shared/constants';
import signupManager from '../signupManager';

export const signupOperations = {
  createUser: createAsyncThunk('signup/CREATE_USER', async (user, { dispatch }) => {
    dispatch(loaderActions.start('createUser'));
    try {
      return await signupManager.createUser(user);
    } finally {
      dispatch(loaderActions.stop('createUser'));
    }
  }),
  resetUserPassword: createAsyncThunk('signup/RESET_USER_PASSWORD', async (user, { dispatch }) => {
    dispatch(loaderActions.start('resetUserPassword'));
    try {
      return await signupManager.resetUserPassword(user);
    } finally {
      dispatch(loaderActions.stop('resetUserPassword'));
    }
  }),
};

export const INITIAL_STATE = {
  cpf: { value: '', valid: false },
  name: { value: '', valid: false },
  email: { value: '', valid: false },
  accept: { value: false, valid: false },
  fetchStatus: {
    createUser: fetchStatus.notFetched,
    resetUserPassword: fetchStatus.notFetched,
  },
};

export const signupSelector = {
  isValid: ({ signup }) => {
    const form = { cpf: signup.cpf, name: signup.name, email: signup.email, accept: signup.accept };
    return Object.values(form).every((element) => element.valid);
  },
};

const signupSlice = createSlice({
  name: 'signup',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setValue: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
    resetFetchStatus: (state) => {
      state.fetchStatus = { ...INITIAL_STATE.fetchStatus };
    },
  },
  extraReducers: {
    [signupOperations.createUser.fulfilled]: (state) => {
      state.fetchStatus.createUser = fetchStatus.fulfilled;
    },
    [signupOperations.createUser.rejected]: (state) => {
      state.fetchStatus.createUser = fetchStatus.rejected;
    },
    [signupOperations.resetUserPassword.fulfilled]: (state) => {
      state.fetchStatus.resetUserPassword = fetchStatus.fulfilled;
    },
    [signupOperations.resetUserPassword.rejected]: (state) => {
      state.fetchStatus.resetUserPassword = fetchStatus.rejected;
    },
  },
});

export default signupSlice;
