import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
    fontWeight: 400,
    marginBottom: 22,
  },
  helperText: {
    marginBottom: -22,
  },
});

const AutocompleteInput = ({
  value,
  onChange,
  isValid,
  errorMessage,
  label,
  placeholder,
  loadingText,
  noOptionsText,
  disabled,
  required,
  isLoading,
  autocompleteOptions,
  optionLabel,
  minSize,
  searchOptions,
  ...props
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState();
  const [openProperty, setOpenProperty] = useState(false);
  const [hasError, setError] = useState(false);
  const [hasTouched, setTouched] = useState(false);

  const handleOnBlur = () => {
    setTouched(true);
    setOpenProperty(false);
  }

  useEffect(() => {
    if (!isEmpty(inputValue) && inputValue.length >= minSize) {
      setOpenProperty(true);
      searchOptions(inputValue);
    } else {
      setOpenProperty(false);
    }
  }, [minSize, inputValue, searchOptions]);

  useEffect(() => {
    if (required && hasTouched && isEmpty(value)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [required, hasTouched, value])

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <Autocomplete
          autoComplete
          clearOnBlur={false}
          includeInputInList
          disabled={disabled}
          value={value ? value : null}
          options={autocompleteOptions}
          onInputChange={(e) => setInputValue(e?.target?.value)}
          getOptionLabel={optionLabel}
          loading={isLoading}
          open={openProperty}
          loadingText={loadingText}
          noOptionsText={noOptionsText}
          forcePopupIcon={false}
          onChange={(e, v) => {
            onChange(v, v ? true : false);
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              autoFocus
              onBlur={handleOnBlur}
              error={hasError}
              label={label}
              margin="normal"
              placeholder={placeholder}
              value={inputValue}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          }
          {...props}
        />
        {(hasError) && (
          <FormHelperText className={classes.helperText} error={hasError}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

AutocompleteInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  loadingText: PropTypes.string,
  noOptionsText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  autocompleteOptions: PropTypes.array,
  optionLabel: PropTypes.func,
  minSize: PropTypes.number,
  searchOptions: PropTypes.func,
};

AutocompleteInput.defaultProps = {
  errorMessage: 'Campo obrigatório',
  label: '',
  placeholder: '',
  loadingText: 'Carregando opções...',
  noOptionsText: 'Nenhuma opção encontrada',
  disabled: false,
  required: false,
  isLoading: false,
  autocompleteOptions: [],
  optionLabel: (option) => option.name,
  minSize: 0,
  searchOptions: () => { },
};

export default AutocompleteInput;
