import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Paper, Button, makeStyles, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { formatToBrlMoney } from 'src/shared/helpers';
import { MessageBox } from 'src/shared/components';
import { productNames } from 'src/shared/constants';
import { QuoteDocument } from 'src/shared/assets/svg';
import { useGoogleAnalytics } from 'src/shared/hooks';
import { getAcceptProposalFulfilled } from '../redux/proposalSelector';
import ProposalAcceptTerms from './ProposalAcceptTerms';
import useIssuePolicy from '../hooks/useIssuePolicy';

const useStyles = makeStyles((theme) => ({
  question: {
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    marginTop: 10,
  },
  button: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 20,
    },
    width: 170,
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  image: {
    width: 60,
    marginTop: 5,
  },
  content: {
    marginTop: 50,
  },
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 40px)',
    },
    padding: 20,
    marginBottom: 20,
  },
  receiptCard: {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  divider: {
    width: '85%',
    marginTop: 10,
  },
  highlightedInfo: {
    color: theme.palette.text.primary,
    fontFamily: '"Open Sans", sans-serif',
  },
  flexColumn: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  boletoInfo: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: 10,
    marginBottom: 30,
  },
  acceptTerms: {
    marginBottom: 15,
    textAlign: 'center',
  },
}));

const ProposalPaymentCard = ({ proposal, isProposalLoading }) => {
  const classes = useStyles();
  const { event } = useGoogleAnalytics();
  const wasProposalAccepted = useSelector(getAcceptProposalFulfilled);
  const {
    policyCanBeIssued,
    issuePolicy,
    openIssuanceConfirmation,
    isIssuanceConfirmationOpened,
    closeIssuanceConfirmation,
    closeIssuePolicyError,
    hasIssuePolicyError,
  } = useIssuePolicy();

  useEffect(() => {
    if (wasProposalAccepted) {
      event('Proposal', 'ProposalAccepted', `${productNames[proposal.productKey]}`);
    }
  }, [event, wasProposalAccepted, proposal.productKey, proposal.proposalNumber]);

  return (
    <>
      <MessageBox
        opened={hasIssuePolicyError}
        type="error"
        handleClose={closeIssuePolicyError}
        handleSecondary={closeIssuePolicyError}
        labelSecondary="Fechar"
        title="Erro ao emitir a apólice"
        text="Ocorreu um erro ao gerar sua apólice. Por favor, tente mais tarde ou entre em contato em um dos canais de comunicação."
      />
      <MessageBox
        opened={isIssuanceConfirmationOpened}
        type="success"
        handleClose={closeIssuanceConfirmation}
        handlePrimary={issuePolicy}
        handleSecondary={closeIssuanceConfirmation}
        labelPrimary="Emitir"
        labelSecondary="Fechar"
        title="Tudo certo para a emissão da sua apólice!"
        text="Ao clicar em emitir, sua apólice e o boleto serão gerados conforme a proposta apresentada."
      />

      <Paper elevation={3} className={clsx(classes.card, classes.receiptCard)}>
        <div className={classes.flexColumn}>
          <img src={QuoteDocument} alt="QuoteDocument" className={classes.image} />
          <Typography className={classes.cardTitle}>
            {isProposalLoading ? (
              <Skeleton animation="wave" height={20} width={150} variant="rect" />
            ) : (
              <>{productNames[proposal.productKey]}</>
            )}
          </Typography>
          <Divider variant="middle" className={classes.divider} />
        </div>
        <div className={clsx(classes.flexColumn)}>
          <Typography variant="h6" className={classes.highlightedInfo}>
            VALOR DO SEGURO:
          </Typography>
          {isProposalLoading ? (
            <Skeleton animation="wave" height={40} width={150} variant="rect" />
          ) : (
            <Typography variant="h4" className={classes.highlightedInfo}>
              {formatToBrlMoney(proposal.premium)}
            </Typography>
          )}
        </div>

        <div className={classes.flexColumn}>
          <Divider variant="middle" className={classes.divider} />
          <Typography className={classes.boletoInfo}>
            A forma de pagamento será boleto bancário com prazo de 15 dias, contados da data da
            emissão.
          </Typography>
        </div>

        <div className={classes.acceptTerms}>
          <ProposalAcceptTerms
            productKey={proposal.productKey}
            proposalId={proposal.proposalId}
            proposalStatus={proposal.status}
          />
        </div>

        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={!policyCanBeIssued}
          onClick={openIssuanceConfirmation}
        >
          Emitir Apólice
        </Button>
      </Paper>
    </>
  );
};

ProposalPaymentCard.propTypes = {
  proposal: PropTypes.object,
  isProposalLoading: PropTypes.bool.isRequired,
};

ProposalPaymentCard.defaultProps = {
  proposal: {},
};

export default ProposalPaymentCard;
