import { authManager } from 'src/features/auth/authManager';

const axiosInstance = authManager.getAxiosInstance();
class HyperlinkService {
  getHyperlink = async (hyperlinkId, notificationId, receiverId) => {
    return await axiosInstance.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/v1/hyperlinks/${hyperlinkId}/quote-data?notificationId=${notificationId}&receiverId=${receiverId}`
    );
  };

  issuePolicy = async (hyperlinkId, concludedBy) => {
    return await axiosInstance.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/v1/hyperlinks/accept`,
      {
        hyperlinkId,
        concludedBy,
      }
    );
  };

  getDraftPDF = async (productKey, quoteId) => {
    return await axiosInstance.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/${productKey}/quotes/${quoteId}/draft`
    );
  };
}

export default new HyperlinkService();
