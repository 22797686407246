import quoteService from './quoteService';

class QuoteManager {
  getQuoteById = quoteService.getQuoteById;

  updateQuote = async ({ quoteId, productKey, insuredAmount, additionalCoverageIncluded }) => {
    const request = {
      insuredAmount,
      additionalCoverageIncluded,
    };

    return quoteService.updateQuote({ quoteId, productKey, request });
  };

  createProposal = async ({ productKey, quoteId }) => {
    const response = await quoteService.createProposal({ productKey, quoteId });

    return response.proposalId;
  };
}

export default new QuoteManager();
