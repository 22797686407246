import React from 'react';
import clsx from 'clsx';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { Question } from 'src/shared/components';
import { AvatarAssistant } from 'src/shared/assets/svg';
import { productComplement } from 'src/shared/constants';
import { useQuote } from '../hooks';
import QuoteDetailsCard from './QuoteDetailsCard';
import QuoteReceiptCard from './QuoteReceiptCard';
import AdditionalCoverageCard from './AdditionalCoverageCard';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
  },
  product: {
    textAlign: 'center',
    fontSize: '0.7rem',
    letterSpacing: 1,
    textTransform: 'none',
  },
  productName: {
    marginTop: 35,
    textTransform: 'uppercase',
  },
  question: {
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  image: {
    width: 60,
    marginTop: 5,
  },
  content: {
    marginTop: 50,
  },
}));

const QuotePage = () => {
  const classes = useStyles();
  const { quote, productKey } = useQuote();

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12}>
        <Typography
          data-testid="productName"
          className={clsx(classes.product, classes.productName)}
        >
          {quote.productName || '-'}
        </Typography>
      </Grid>
      {productComplement[productKey] && (
        <Grid item xs={12}>
          <Typography data-testid="productName" className={classes.product}>
            {quote.productName ? productComplement[productKey] : ''}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={6} className={classes.question}>
        <Question
          avatar={AvatarAssistant}
          question="Agradecemos seu cadastro! Veja aqui sua cotação:"
        />
      </Grid>
      <Grid item xs={12} md={8} className={classes.content}>
        <Grid container justify="center">
          <Grid item xs={11} md={6}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <QuoteDetailsCard
                  quote={quote}
                  productKey={quote.productKey}
                />
              </Grid>
              {quote.hasAdditionalCoverage ? (
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                  <AdditionalCoverageCard quote={quote} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={11} md={4}>
            <QuoteReceiptCard quote={quote} productKey={productKey} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuotePage;
