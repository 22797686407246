import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { Typography, makeStyles } from '@material-ui/core';
import { IcoWhats } from 'src/shared/assets/svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: 35,
    bottom: 85,
    [theme.breakpoints.up('lg')]: {
      bottom: 90,
    },
    [theme.breakpoints.down('xs')]: {
      right: 20,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  btn: {
    width: 40,
    height: 40,
    background: '#55CA64',
    display: 'block',
    textAlign: 'center',
    padding: 9,
    borderRadius: ' 50%',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: 35,
      height: 35,
    },
  },
}));

const WhatsBtn = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid="whats-btn">
      <a
        className={classes.btn}
        href="https://api.whatsapp.com/send?phone=553121217777&text=Olá!"
        target="_blank"
        rel="noopener noreferrer"
        to="/attendance"
        aria-owns={open ? 'atendimento-whats' : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <img src={IcoWhats} alt="ico-whats" />
      </a>
      <Popover
        id="atendimento-whats"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>Fale por Whatsapp</Typography>
      </Popover>
    </div>
  );
};

export default WhatsBtn;
