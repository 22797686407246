import { createAction } from '@reduxjs/toolkit';

export const cleanQuiz = createAction('quiz/CLEAN_QUIZ');
export const cleanErrors = createAction('quiz/CLEAN_ERRORS');
export const cleanInsured = createAction('quiz/CLEAN_INSURED');
export const setStepperPosition = createAction('quiz/SET_STEP_POSITION');
export const setQuizProperty = createAction('quiz/SET_QUOTE_PROPERTY');
export const setQuoteId = createAction('quiz/SET_QUOTE_ID');
export const setInsuredError = createAction('quiz/SET_INSURED_ERROR');
export const setQuestionContentPreviewText = createAction('quiz/SET_QUESTION_CONTENT_PREVIEW_TEXT');
export const setQuestionPlaceholder = createAction('quiz/SET_QUESTION_PLACEHOLDER');
export const setQuestionTitle = createAction('quiz/SET_QUESTION_TITLE');
export const setQuestionBypass = createAction('quiz/SET_QUESTION_BYPASS');
export const setQuestionRequired = createAction('quiz/SET_QUESTION_REQUIRED');
