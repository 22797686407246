import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getProductKeyByPathname } from 'src/shared/helpers';
import { proposalActions } from '../redux';

export default () => {
  const { location } = useHistory();
  const dispatch = useDispatch();

  const productKey = getProductKeyByPathname(location.pathname);
  const policyUrl = useSelector(({ proposal }) => proposal.policyUrl);

  useEffect(
    () => () => {
      dispatch(proposalActions.cleanProposal());
    },
    [dispatch]
  );

  return { policyUrl, productKey };
};
