import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { Question } from 'src/shared/components';
import { AvatarAssistant } from 'src/shared/assets/svg';
import { productComplement, productNames } from 'src/shared/constants';
import { useProposal } from 'src/features/proposal/hooks';
import { useGoogleAnalytics } from 'src/shared/hooks';
import ProposalDetailsCard from './ProposalDetailsCard';
import ProposalPaymentCard from './ProposalPaymentCard';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 120,
    alignItems: 'center',
  },
  product: {
    textAlign: 'center',
    fontSize: '0.7rem',
    letterSpacing: 1,
    textTransform: 'none',
  },
  productName: {
    textTransform: 'uppercase',
    marginTop: 35,
  },
  question: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  content: {
    marginTop: 32,
  },
  termsContent: {
    marginTop: 16,
  },
}));

const ProposalPage = () => {
  const classes = useStyles();
  const { proposal, isProposalLoading } = useProposal();
  const { event } = useGoogleAnalytics();

  useEffect(() => {
    if (proposal.proposalNumber && proposal.productKey) {
      event('Proposal', 'ProposalCreated', `${productNames[proposal.productKey]}`);
    }
  }, [event, proposal.proposalNumber, proposal.productKey]);

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12}>
        <Typography data-testid="title" className={clsx(classes.product, classes.productName)}>
          {productNames[proposal.productKey] ?? '-'}
        </Typography>
      </Grid>
      {productComplement[proposal.productKey] && (
        <Grid item xs={12}>
          <Typography data-testid="productName" className={classes.product}>
            {productNames[proposal.productKey] ? productComplement[proposal.productKey] : ''}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={6} className={classes.question}>
        <Question avatar={AvatarAssistant} question="Estamos quase lá! Veja aqui sua proposta:" />
      </Grid>
      <Grid item xs={12} md={8} className={classes.content}>
        <Grid container justify="center" spacing={5}>
          <Grid item xs={11} md={6}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <ProposalDetailsCard proposal={proposal} isProposalLoading={isProposalLoading} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11} md={4}>
            <ProposalPaymentCard proposal={proposal} isProposalLoading={isProposalLoading} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProposalPage;
