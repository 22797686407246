import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { PottencialIcon } from 'src/shared/assets/svg';
import { LoginBackground, Logo } from 'src/shared/assets/img';
import { useHistory } from 'react-router-dom';
import { routes } from 'src/shared/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  bgContainer: {
    backgroundImage: `url(${LoginBackground})`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
  },
  logoTitle: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(1),
    },
  },
  logo: {
    background: '#F25A0F',
    cursor: 'pointer',
    width: 160,
    margin: theme.spacing(2),
    display: 'flex',
    '& img': {
      maxWidth: '100%',
    },
  },
  formContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    color: '#333333',
  },
}));

const SignupPage = ({ children }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const goToHome = () => push(routes.home.path);

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.bgContainer} sm={5}>
        <img
          className={classes.logo}
          onClick={goToHome}
          src={Logo}
          alt="logo"
          aria-label="Pottencial"
          aria-hidden="true"
        />
      </Grid>
      <Grid item sm={7}>
        <div className={classes.formContainer}>
          <div className={classes.logoTitle}>
            <img src={PottencialIcon} alt="Pottencial" aria-label="Pottencial" />
            <Typography variant="h6" className={classes.title} color="primary">
              POTTENCIAL GARANTIA DIGITAL
            </Typography>
          </div>
          {children}
        </div>
      </Grid>
    </Grid>
  );
};

SignupPage.propTypes = {
  children: PropTypes.object.isRequired,
};

export default SignupPage;
