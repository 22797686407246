import React from 'react';
import { useSpring, animated } from 'react-spring';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#00000090',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  loading: {
    width: 60,
    maxWidth: '100%',
    animation: '$spin 2.5s linear infinite',
  },
  svgContainer: {
    transform: 'scale(1,-1)',
  },
  content: {
    padding: '30px 60px',
  },
  cls1: {
    fill: '#FFF',
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(-360deg)',
    },
  },
});

const Loader = () => {
  const classes = useStyles();
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.div data-testid="loader" style={props} className={classes.root}>
      <div className={classes.svgContainer}>
        <svg
          className={classes.loading}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 59.04 58.98"
        >
          <g>
            <path
              className={classes.cls1}
              d="M212.73,165.82h0a23.06,23.06,0,0,1-18.52-9.25,1.87,1.87,0,0,1,.35-2.62l.14-.1a1.83,1.83,0,0,1,2.43.43,19.34,19.34,0,0,0,15.6,7.83Z"
              transform="translate(-183.11 -112.16)"
            />
            <path
              className={classes.cls1}
              d="M225,159.84h0a1.83,1.83,0,0,1,.43-2.43,19.34,19.34,0,0,0,7.84-15.62l3.71-.09v.09a23,23,0,0,1-9.26,18.54,1.87,1.87,0,0,1-2.63-.35Z"
              transform="translate(-183.11 -112.16)"
            />
            <path
              className={classes.cls1}
              d="M188.63,141.84v-.05a23,23,0,0,1,9.21-18.5,1.88,1.88,0,0,1,2.63.34l.1.14h0a1.83,1.83,0,0,1-.43,2.43,19.35,19.35,0,0,0-7.8,15.59Z"
              transform="translate(-183.11 -112.16)"
            />
            <path
              className={classes.cls1}
              d="M228.29,129a19.39,19.39,0,0,0-15.59-7.79l-.06-3.71h.24a22.92,22.92,0,0,1,18.32,9.2,1.87,1.87,0,0,1-.34,2.62l-.14.1A1.83,1.83,0,0,1,228.29,129Z"
              transform="translate(-183.11 -112.16)"
            />
          </g>
          <path
            className={classes.cls1}
            d="M228.29,146a1.86,1.86,0,0,1,0-2.62h0l6.66-6.66,6.66,6.66A1.85,1.85,0,0,1,239,146l-4-4-4,4a1.85,1.85,0,0,1-2.62,0Z"
            transform="translate(-183.11 -112.16)"
          />
          <path
            className={classes.cls1}
            d="M190.31,146.8l-6.66-6.66a1.85,1.85,0,1,1,2.62-2.62l4,4,4-4a1.85,1.85,0,1,1,2.62,2.62Z"
            transform="translate(-183.11 -112.16)"
          />
          <path
            className={classes.cls1}
            d="M207.52,170.6a1.86,1.86,0,0,1,0-2.62h0l4-4-4-4a1.86,1.86,0,1,1,2.62-2.63l6.66,6.66-6.66,6.66a1.84,1.84,0,0,1-2.62,0Z"
            transform="translate(-183.11 -112.16)"
          />
          <path
            className={classes.cls1}
            d="M215.27,126l-6.66-6.66,6.66-6.66a1.85,1.85,0,0,1,2.62,2.62l-4,4,4,4a1.85,1.85,0,0,1-2.62,2.62Z"
            transform="translate(-183.11 -112.16)"
          />
        </svg>
      </div>
    </animated.div>
  );
};

export default Loader;
