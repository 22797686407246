import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button, makeStyles } from '@material-ui/core';

import { ModalInfo } from 'src/shared/components';

const useStyles = makeStyles(() => ({
  text: {
    background: '#FFF',
    overflow: 'auto',
    maxHeight: 480,
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
      background: '#F7F7F7 0% 0% no-repeat padding-box',
    },
  },
  btn: {
    width: '100%',
  },
}));

const TermModal = ({
  open,
  setOpen,
  title,
  subTitle,
  showAcceptButton,
  acceptTerms,
  paragraphs,
  children,
}) => {
  const classes = useStyles();

  if (isNil(paragraphs) && isNil(children)) return null;

  return (
    <ModalInfo open={open} className={classes.modal} onClose={() => setOpen(false)}>
      <Box textAlign="center" data-testid="term-modal">
        <Typography variant="h5">{title}</Typography>
        <Box px={3} mb={2} mt={5}>
          <Typography variant="body1">{subTitle}</Typography>
        </Box>
        <Box className={classes.text} mb={5} p={3} textAlign="left">
          {isNil(paragraphs)
            ? children
            : paragraphs.map(({ key, content }) => (
                <Box mb={3} key={key}>
                  <Typography variant="body2" align="justify">
                    {content}
                  </Typography>
                </Box>
              ))}
        </Box>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              color="primary"
              variant="outlined"
              className={classes.btn}
              onClick={() => setOpen(false)}
            >
              Voltar
            </Button>
          </Grid>
          {showAcceptButton && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Button
                color="primary"
                variant="contained"
                className={classes.btn}
                onClick={() => {
                  acceptTerms();
                  setOpen(false);
                }}
              >
                Li e Aceito
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </ModalInfo>
  );
};

TermModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showAcceptButton: PropTypes.bool,
  acceptTerms: PropTypes.func,
  paragraphs: PropTypes.array,
  children: PropTypes.element,
};

TermModal.defaultProps = {
  title: 'Termos e condições',
  subTitle:
    'Prezado usuário, é muito importante a leitura atenta destes Termos, pois você estará aceitando as nossas regras.',
  showAcceptButton: true,
  acceptTerms: () => null,
  paragraphs: null,
  children: null,
};

export default TermModal;
