import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loaderActions } from 'src/features/loader/redux';
import { Typography, Grid } from '@material-ui/core';
import { useGoogleAnalytics } from 'src/shared/hooks';
import { authManager } from '../authManager';
import { authSelector } from '../redux/authSlice';

const Login = ({ children }) => {
  const { location } = useHistory();
  const isAxiosAuthorized = useSelector(authSelector.getAxiosAuthorized);
  const user = useSelector(({ oidc }) => oidc.user);
  const isLoadingUser = useSelector(({ oidc }) => oidc.isLoadingUser);
  const isLoggingOut = useSelector(({ auth }) => auth.isLoggingOut);
  const dispatch = useDispatch();
  const userManager = authManager.getUserManager();
  const userExpired = user?.expired;
  const { event } = useGoogleAnalytics();

  const userUnauthorizedOrExpired = useMemo(
    () => !isLoggingOut && !isLoadingUser && (!user || userExpired),
    [user, isLoggingOut, isLoadingUser, userExpired]
  );
  const userAuthorized = useMemo(() => isAxiosAuthorized && user && !userExpired, [
    isAxiosAuthorized,
    user,
    userExpired,
  ]);

  useEffect(() => {
    dispatch(loaderActions.start('Login'));
    return () => dispatch(loaderActions.stop('Login'));
  }, [dispatch]);

  useEffect(() => {
    if (isLoggingOut) {
      dispatch(loaderActions.start('Login'));
    }
  }, [dispatch, isLoggingOut]);

  useEffect(() => {
    if (userUnauthorizedOrExpired) {
      userManager.signinRedirect({ state: { callbackUrl: location.pathname } });
    }
  }, [userUnauthorizedOrExpired, userManager, location.pathname]);

  useEffect(() => {
    if (userAuthorized) {
      dispatch(loaderActions.stop('Login'));
      event('Account', 'Login');
    }
  }, [dispatch, userAuthorized, event]);

  if (userAuthorized) {
    return children;
  }

  return (
    <Grid container style={{ padding: '25%' }} justify="center" alignItems="center">
      <Grid item style={{ padding: '20px 0' }}>
        <Typography variant="body1">Um momento...</Typography>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Login;
