import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomLineTitle } from 'src/shared/components/CustomLineTitle';
import { LoaderInformative, MessageBox } from 'src/shared/components';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'src/shared/constants';
import useDownload from 'src/shared/hooks/useDownload';
import QuoteDetailsCard from '../../components/quote-details-card/QuoteDetailsCard';
import { useStyles } from './styles';
import hyperlinkService from '../../hyperlinkService';

const QuoteReady = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { generateUrlPdf } = useDownload();
  const [openModal, setOpenModal] = useState({
    open: false,
    title: '',
    message: '',
    type: 'success',
    onClick: () => {},
    textButtton: '',
  });
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { push } = useHistory();
  const [data, setData] = useState();

  const returnToHome = useCallback(() => {
    push(routes.home.path);
  }, [push]);

  const getIDSFromURL = () => {
    const params = new URLSearchParams(search);
    const hyperlinkId = params.get('hyperlink');
    const notificationId = params.get('notification');
    const receiverId = params.get('receiver');
    const email = params.get('email');

    return {
      hyperlinkId,
      notificationId,
      receiverId,
      email,
    };
  };

  const onCloseModal = () => {
    setOpenModal({
      open: false,
    });
  };

  const handleGetHyperlink = () => {
    const { hyperlinkId, notificationId, receiverId } = getIDSFromURL();
    setLoading(true);
    hyperlinkService
      .getHyperlink(hyperlinkId, notificationId, receiverId)
      .then(
        (response) => {
          setData(response.data);
        },
        (error) => {
          const codeAlreadyApproved = error.response?.data.some(
            (errorItem) => errorItem.code === '100.002'
          );
          if (codeAlreadyApproved) {
            push(routes.hyperlink.alreadyApproved);
            return;
          }
          push(routes.hyperlink.invalid);
        }
      )
      .finally(() => setLoading(false));
  };

  const downloadDraft = () => {
    if (data?.id && data?.productName) {
      setLoadingButton(true);
      hyperlinkService
        .getDraftPDF(data?.productKey, data?.id)
        .then((response) => {
          const url = generateUrlPdf(response.data.fileContents);
          window.open(url);
        })
        .catch((error) => {
          setOpenModal({
            open: true,
            title: 'Ops!',
            message: error.response?.data[0]?.message,
            type: 'error',
            onClick: onCloseModal,
            textButtton: 'Fechar',
          });
        })
        .finally(() => setLoadingButton(false));
    }
  };

  const handleIssuePolicy = () => {
    const { hyperlinkId, email } = getIDSFromURL();
    setLoading(true);
    hyperlinkService
      .issuePolicy(hyperlinkId, email)
      .then(
        () => {
          setOpenModal({
            open: true,
            title: 'Emissão em andamento!',
            message:
              'Informamos que o processo de emissão da sua apólice já foi iniciado e não poderá ser cancelado. Enviaremos um e-mail assim que o processo de emissão for concluído.',
            type: 'success',
            onClick: returnToHome,
            textButtton: 'Voltar ao início',
          });
        },
        (error) => {
          setOpenModal({
            open: true,
            title: 'Ops! Não foi possível prosseguir com a sua emissão..',
            message: error.response?.data[0]?.message,
            type: 'error',
            textButtton: 'Fechar',
            onClick: onCloseModal,
          });
        }
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetHyperlink();
  }, []);

  return (
    <>
      {loading && <LoaderInformative loadingText="Aguarde um instante..." />}
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid item xs={10} md={6} lg={5} className={classes.header}>
          <CustomLineTitle title="Prezados(as)" />
          <Typography className={classes.text}>
            Apresentamos os dados do seguro {data?.productName} aprovado pela Pottencial Seguradora.{' '}
          </Typography>
        </Grid>

        <Grid item xs={10} md={6} lg={5} className={classes.boxCard}>
          <QuoteDetailsCard data={data} />
        </Grid>
        <Grid item xs={10} md={6} lg={5} className={classes.boxFooter}>
          <Typography className={classes.titleFooter}>
            Seguem as instruções para a emissão{' '}
          </Typography>
          <Typography className={classes.textFooter}>
            Caso esteja de acordo com as condições e com os dados da minuta, gentileza clicar no
            botão "EMITIR APÓLICE" e a emissão automática será iniciada.
          </Typography>
          <Grid className={classes.boxButtons}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={downloadDraft}
              endIcon={loadingButton ? <CircularProgress color="primary" size={15} /> : null}
            >
              VISUALIZAR MINUTA
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleIssuePolicy}
            >
              EMITIR APÓLICE
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={10} md={6} lg={5} className={classes.boxObservation}>
          <Typography className={classes.titleObservation}>Observações </Typography>
          <Typography className={classes.textObservation}>
            A validade desta aprovação é de {data?.daysToExpire} dias.
          </Typography>
          <Typography className={classes.textObservation}>
            Caso o tomador/grupo tenha prêmio pendente na Pottencial Seguradora no momento da
            autorização da emissão, o mesmo deverá ser regularizado para que o processo de emissão
            tenha continuidade.
          </Typography>
        </Grid>
        <MessageBox
          opened={openModal.open}
          type={openModal.type}
          handleClose={openModal.onClick}
          handleSecondary={openModal.onClick}
          labelSecondary={openModal.textButtton}
          title={openModal.title}
          text={openModal.message}
        />
      </Grid>
    </>
  );
};

export default QuoteReady;
