import React from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteInput,
  CnpjInput,
  Datepicker,
  NumberInput,
  RadioField,
  SelectField,
  TextInput
} from 'src/shared/components';

export const inputTypes = Object.freeze({
  MONEY: 'MONEY',
  CNPJ: 'CNPJ',
  INTEGER: 'INTEGER',
  TEXT: 'TEXT',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  RADIO: 'RADIO',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
});

const InputFactory = ({ question, value, setValue, ...props }) => {
  const defaultReturn = <div style={{ height: 70 }} />;
  if (!question.type) return defaultReturn;

  switch (question.type) {
    case inputTypes.CNPJ:
      return (
        <CnpjInput
          key={question.type}
          value={value}
          onChange={setValue}
          placeholder={question.placeholder}
          tooltip={question.usedescriptionastooltip ? question.description ?? null : null}
          {...props}
        />
      );
    case inputTypes.MONEY:
      return (
        <NumberInput
          key={question.type}
          value={Number(value)}
          onChange={setValue}
          placeholder={question.placeholder}
          min={question.validation?.min}
          max={question.validation?.max}
          numberFormatProps={{ prefix: 'R$', decimalScale: 2, fixedDecimalScale: true }}
          tooltip={question.usedescriptionastooltip ? question.description ?? null : null}
          {...props}
        />
      );
    case inputTypes.INTEGER:
      return (
        <NumberInput
          key={question.type}
          value={Number(value)}
          onChange={setValue}
          placeholder={question.placeholder}
          min={question.validation?.min}
          max={question.validation?.max}
          {...props}
        />
      );
    case inputTypes.TEXT:
      return (
        <TextInput
          key={question.type}
          value={value}
          onChange={setValue}
          placeholder={question.placeholder}
          min={question.validation?.min}
          max={question.validation?.max}
          tooltip={question.usedescriptionastooltip ? question.description ?? null : null}
          {...props}
        />
      );
    case inputTypes.DATE:
      return (
        <Datepicker
          key={question.type}
          value={value || undefined}
          onChange={setValue}
          min={question.validation?.min}
          max={question.validation?.max}
          {...props}
        />
      );
    case inputTypes.DROPDOWN:
      return (
        <SelectField
          key={question.type}
          value={value || undefined}
          onChange={setValue}
          options={question.options}
          placeholder={question.placeholder}
          {...props}
        />
      );
    case inputTypes.RADIO:
      return (
        <RadioField
          key={question.type}
          value={value || undefined}
          onChange={setValue}
          options={question.options}
          {...props}
        />
      );
    case inputTypes.AUTOCOMPLETE:
      return (
        <AutocompleteInput
          key={question.type}
          value={value}
          onChange={setValue}
          required={question.required}
          placeholder={question.placeholder}
          loadingText={question.loadingText}
          noOptionsText={question.noOptionsText}
          disabled={question.disabled}
          minSize={3}
          {...props}
        />
      );
    default:
      return defaultReturn;
  }
};

InputFactory.propTypes = {
  question: PropTypes.object,
};

InputFactory.defaultProps = {
  question: {},
};

export default InputFactory;
