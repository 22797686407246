import { createSlice } from '@reduxjs/toolkit';
import { fetchStatus } from 'src/shared/constants';
import * as quoteOperations from './quoteOperations';

export const INITIAL_STATE = {
  quoteId: '',
  productKey: '',
  productName: '',
  premium: null,
  insuredAmount: null,
  hasAdditionalCoverage: null,
  additionalCoverageIncluded: null,
  properties: {},
  policyholderName: '',
  status: null,
  fetchStatus: {
    getQuoteById: fetchStatus.notFetched,
    updateQuote: fetchStatus.notFetched,
  },
  proposal: {
    fetchStatus: fetchStatus.notFetched,
    id: null,
    error: {},
  },
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState: INITIAL_STATE,
  reducers: {
    setInsuredAmount(state, action) {
      state.insuredAmount = action.payload;
    },
    setQuote(state, action) {
      return { ...state, ...action.payload };
    },
    cleanQuote() {
      return INITIAL_STATE;
    },
    cleanErrors(state) {
      return { ...state, fetchStatus: INITIAL_STATE.fetchStatus };
    },
  },
  extraReducers: {
    [quoteOperations.getQuoteById.fulfilled]: (state, action) => {
      const newFetchStatus = { ...state.fetchStatus, getQuoteById: fetchStatus.fulfilled };
      return { ...state, ...action.payload, fetchStatus: newFetchStatus };
    },
    [quoteOperations.getQuoteById.rejected]: (state) => {
      state.fetchStatus.getQuoteById = fetchStatus.rejected;
    },
    [quoteOperations.updateQuote.fulfilled]: (state, action) => {
      const newFetchStatus = { ...state.fetchStatus, updateQuote: fetchStatus.fulfilled };
      return { ...state, ...action.payload, fetchStatus: newFetchStatus };
    },
    [quoteOperations.updateQuote.rejected]: (state, action) => {
      if (action.error.message.match('403')) {
        state.fetchStatus.updateQuote = fetchStatus.forbidden;
      } else {
        state.fetchStatus.updateQuote = fetchStatus.rejected;
      }
    },
    [quoteOperations.createProposal.fulfilled]: (state, action) => {
      state.proposal.fetchStatus = fetchStatus.fulfilled;
      state.proposal.id = action.payload;
    },
    [quoteOperations.createProposal.rejected]: (state, action) => {
      if (action.error.message.match('403')) {
        state.proposal.fetchStatus = fetchStatus.forbidden;
      } else {
        state.proposal.fetchStatus = fetchStatus.rejected;
      }
      state.proposal.error = action.error;
    },
  },
});

export default quoteSlice;
