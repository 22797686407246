export const fetchStatus = Object.freeze({
  notFetched: 'notFetched',
  fulfilled: 'fulfilled',
  pending: 'pending',
  rejected: 'rejected',
  forbidden: 'forbidden',
  unauthorized: 'unauthorized',
});

export const enterKey = 13;

export const loaderTypes = Object.freeze({
  default: 'default',
  informative: 'informative',
});

export const quoteStatus = Object.freeze({
  approved: 'APPROVED',
  rejected: 'REJECTED',
  unauthorized: 'UNAUTHORIZED',
});

export const productNames = Object.freeze({
  garantiaBidPublico: 'Garantia do Licitante (BID)',
  garantiaPerformancePublico: 'Garantia Performance',
});

export const fullProductNames = Object.freeze({
  garantiaBidPublico: 'Garantia Licitante',
  garantiaPerformancePublico: 'Garantia Performance',
});

export const productComplement = Object.freeze({
  garantiaPerformancePublico: '(Construção, Fornecimento ou Prestação de Serviço)',
});

export const maxInsuredAmount = 500000;

export const percentageInsuredAmount = Object.freeze({
  garantiaBidPublico: '1%',
  garantiaPerformancePublico: '5%',
});

export const participantRoles = Object.freeze({
  policyHolder: 'PolicyHolder',
  insured: 'Insured',
});

export const proposalStatus = Object.freeze({
  pending: 'PENDING',
  ready: 'READY',
  issued: 'ISSUED',
  accepted: 'ACCEPTED',
  done: 'DONE',
  rejected: 'REJECTED',
});

export const noDisplayableValues = ['Outro'];

export const baseNumeroEditalContent =
  'Este seguro garante a indenização, até o valor da garantia fixado na apólice, se o Tomador adjudicatário se recusar a assinar o Contrato Principal, nas condições propostas, dentro do prazo estabelecido no(a)';

export const googleAnalyticsTrackingCode = 'UA-178051078-1';

export const GLOBAL_MEDIA_QUERIES = {
  small: '(max-width: 499px)',
  medium: '(min-width: 500px) and (max-width: 1199px)',
  large: '(min-width: 1200px)',
};

export const equalCnpjInsuredAndPolicyholder = 'CNPJ do Segurado e Tomador devem ser diferentes';

export const proposalDraftFail = 'Falha ao recuperar minuta';

/* eslint-disable */
export const regexValidateEmail = new RegExp(/.+@.+\.[A-Za-z]+$/);

export const regexValidateName = new RegExp(/^[^(){}±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,0-9]{1,75}$/);

export const regexValidatePhone = new RegExp(
  /(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[\.-\s]?)[9]?[1-9]\d{3}[\.-\s]?\d{4}$/
);
