import { useSelector } from 'react-redux';
import { getStepperInfo } from 'src/features/quiz/redux/quizSelectors';

export default function () {
  let { size } = useSelector(getStepperInfo);

  if (size <= 1 || size == null) {
    try {
      size = parseInt(localStorage.getItem('quizSize'), 10) || 0;
    } catch {
      return 0;
    }
  }

  return size;
}
