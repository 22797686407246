import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Parser as HtmlParser } from 'html-to-react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { setQuizProperty } from '../redux/quizActions';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '3em',
    padding: '1.2em',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '2px 1px 2px #80808029',
  },
  title: {
    textAlign: 'center',
    fontSize: '0.9em',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: { fontSize: '0.8em' },
    color: '#595959',
  },
  contentContainer: {
    width: '100%',
  },
  content: {
    marginTop: '1em',
    textAlign: 'left',
    fontSize: '0.8em',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: { fontSize: '0.7em' },
    letterSpacing: '0.12px',
    color: '#3C3C3C',
  },
}));

const ContentPreview = ({ propertyName, inputValue, contentPreview, placeholder }) => {
  const { title, content } = contentPreview;
  const classes = useStyles();
  const dispatch = useDispatch();
  const htmlParser = new HtmlParser();

  const [contentFilled, contentFilledPreview] = useMemo(() => {
    const placeholderTemplate = `&lt;${placeholder}&gt;`;
    return [
      content.replace(`{{${propertyName}}}`, `${inputValue}`),
      content.replace(
        `{{${propertyName}}}`,
        `<strong>${inputValue || placeholderTemplate}</strong>`
      ),
    ];
  }, [content, propertyName, inputValue, placeholder]);

  useEffect(() => {
    dispatch(
      setQuizProperty({ name: `${propertyName}_CONTENT`, value: contentFilled, valid: true })
    );
  }, [dispatch, propertyName, contentFilled]);

  if (isEmpty(content)) return null;
  return (
    <div className={classes.paper}>
      <Grid container>
        <Grid item>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item className={classes.contentContainer}>
          <Typography className={classes.content}>
            {htmlParser.parse(contentFilledPreview)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

ContentPreview.propTypes = {
  propertyName: PropTypes.string.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  contentPreview: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
};

ContentPreview.defaultProps = {
  contentPreview: {
    title: '',
    content: '',
    placeholder: '',
  },
};

export default ContentPreview;
