import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  withStyles
} from '@material-ui/core';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    alignItems: 'center',
  },
});

const CustomRadio = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const RadioField = ({ value, onChange, options, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <RadioGroup
        value={value}
        onChange={(e) => {
          onChange(e.target.value, true);
        }}
        ata-testid="radio-field"
        {...props}
      >
        {options.map((option) => (
          <FormControlLabel key={option.value} value={option.value} control={<CustomRadio />} label={option.title} />
        ))}
      </RadioGroup>
    </FormControl >
  );
};

RadioField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
};

RadioField.defaultProps = {
  value: '',
  required: false,
};

export default RadioField;
