import { quizOperations } from 'src/features/quiz/redux';
import { quoteActions } from 'src/features/quote/redux';
import { authManager } from 'src/features/auth/authManager';
import { authActions } from 'src/features/auth/redux';
import { USER_FOUND, USER_EXPIRED, USER_SIGNED_OUT, SESSION_TERMINATED } from 'redux-oidc';

export const storeQuote = (store) => (next) => (action) => {
  if (action.type === quizOperations.createQuote.fulfilled.toString()) {
    const { quiz } = store.getState();
    const quote = {
      productKey: quiz.product.productKey,
      quoteId: action.payload.quoteId,
      status: action.payload.status,
    };

    store.dispatch(quoteActions.setQuote({ ...quote }));

    try {
      localStorage.setItem('quote', JSON.stringify(quote));
    } catch {}
  } else if (action.type === quizOperations.createQuote.rejected.toString()) {
    try {
      localStorage.removeItem('quote');
      store.dispatch(quoteActions.cleanQuote());
    } catch {}
  }
  return next(action);
};

export const setIsAxiosAuthorized = (store) => (next) => (action) => {
  const user = action.payload;
  switch (action.type) {
    case USER_FOUND:
      if (user && !user?.expired) {
        authManager.setAxiosAuthorizationToken({ ...user });
        store.dispatch(authActions.setIsAxiosAuthorized(true));
      }
      break;
    case USER_EXPIRED:
    case USER_SIGNED_OUT:
      authManager.resetAxiosInstance();
      store.dispatch(authActions.setIsAxiosAuthorized(false));
      break;
    case SESSION_TERMINATED:
      try {
        localStorage.clear();
      } catch {}
      break;
    default:
      break;
  }
  return next(action);
};
