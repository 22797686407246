import { authManager } from 'src/features/auth/authManager';

class QuoteService {
  getQuoteById = async ({ quoteId, productKey }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.get(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/${productKey}/quotes/${quoteId}`
    );

    return response.data;
  };

  updateQuote = async ({ quoteId, productKey, request }) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.put(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/${productKey}/quotes/${quoteId}`,
      request
    );

    return response.data;
  };

  createProposal = async (proposalRequest) => {
    const axiosInstance = authManager.getAxiosInstance();
    const response = await axiosInstance.post(
      `${window.env.REACT_APP_BIDPERFORMANCE_BFF}/api/proposals`,
      proposalRequest
    );
    return response.data;
  };
}

export default new QuoteService();
