import { createUserManager } from 'redux-oidc';
import axios from 'axios';
import { WebStorageStateStore } from 'oidc-client';
import { CookieStorage } from 'cookie-storage';
import { addHours } from 'date-fns';

const cookieStorage = new CookieStorage({
  path: '/',
  domain: window.REACT_APP_HOME_URL,
  expires: addHours(new Date(), 4),
  secure: true,
  sameSite: 'Strict',
});

const userManagerConfig = {
  client_id: window.env.REACT_APP_CLIENT_ID,
  redirect_uri: window.env.REACT_APP_REDIRECT_URI,
  response_type: 'token id_token',
  scope: 'openid profile pottencial-garantia-bidperformance-bff',
  authority: window.env.REACT_APP_AUTHORITY,
  silent_redirect_uri: window.env.REACT_APP_SILENT_RENEW_REDIRECT_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  post_logout_redirect_uri: window.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  userStore: new WebStorageStateStore({ store: cookieStorage }),
};

const userManager = createUserManager(userManagerConfig);
let axiosInstance = axios.create();

class AuthManager {
  constructor() {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          await userManager.signinRedirect({ state: { callbackUrl: window.location.pathname } });
          return Promise.resolve();
        }
        return Promise.reject(error);
      }
    );
  }

  getUserManager = () => userManager;

  getAxiosInstance = () => axiosInstance;

  resetAxiosInstance = () => {
    axiosInstance = axios.create();
  };

  setAxiosAuthorizationToken = (user) => {
    if (user) {
      axiosInstance.interceptors.request.use(
        (config) => {
          let headers = config.headers ? { ...config.headers } : {};
          headers = { ...headers, Authorization: `Bearer ${user.access_token}` };
          config.headers = headers;
          return config;
        },
        (error) => Promise.reject(error)
      );
    }
  };
}

export const authManager = new AuthManager();
