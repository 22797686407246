import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Logo } from 'src/shared/assets/svg';
import { useGoogleAnalytics } from 'src/shared/hooks';
import { DEFAULT_WIDTH, GLOBAL_MEDIA_QUERIES } from 'src/shared/constants';
import { Typography, Divider } from '@material-ui/core';
import { useMedia } from 'react-media';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: DEFAULT_WIDTH,
    maxHeight: 64,
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      padding: '0 25px',
    },
  },
  logo: {
    alignSelf: 'center',
    [theme.breakpoints.up('xs')]: {
      width: 170,
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: 125,
      height: 'auto',
    },
  },
  button: {
    alignSelf: 'center',
    fontSize: '0.7em',
    fontWeight: 500,
    width: 150,
    height: 30,
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    borderRadius: '30px',
    opacity: 0.9,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em',
      width: 130,
      height: 25,
    },
  },
  greeting: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    '@media (max-width: 376px)': {
      fontSize: '0.7rem',
      textAlign: 'right',
    },
  },
  logoutButton: {
    fontWeight: 700,
    color: '#FFF',
    textAlign: 'center',
    width: 80,
    height: 35,
    marginLeft: 10,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: 65,
    },
    '@media (max-width: 376px)': {
      fontSize: '0.8rem',
      width: 55,
    },
  },
  link: {
    display: 'flex',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: '#FFF',
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
}));

const AppBar = ({ showRightButton, showUserInfo, userLogout }) => {
  const classes = useStyles();
  const screenSize = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { event } = useGoogleAnalytics();

  const redirectTo = (url) => () => {
    window.location = url;
  };

  const eventClickLogo = () => {
    event('Home', 'ClickedLogo', '');
  };

  return (
    <MuiAppBar position="static">
      <Toolbar>
        <div className={classes.toolbar}>
          <Link
            className={classes.link}
            to="/"
            title="Ir para a página principal"
            aria-label="Ir para a página principal"
            onClick={eventClickLogo}
          >
            <img className={classes.logo} src={Logo} alt="Logo - Pottencial Seguradora" />
          </Link>
          {showRightButton && (
            <Button
              color="secondary"
              className={classes.button}
              onClick={redirectTo('https://pottencial.com.br')}
            >
              SITE POTTENCIAL
            </Button>
          )}
          {showUserInfo && (
            <div className={classes.userInfo}>
              <Typography className={classes.greeting}>
                {screenSize.small
                  ? 'Olá! Seja bem-vindo(a)'
                  : 'Olá! Seja bem-vindo(a) ao Garantia Digital'}
              </Typography>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <Button className={classes.logoutButton} onClick={userLogout}>
                Sair
              </Button>
            </div>
          )}
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  showRightButton: PropTypes.bool,
  showUserInfo: PropTypes.bool,
  userLogout: PropTypes.func,
};

AppBar.defaultProps = {
  showRightButton: false,
  showUserInfo: false,
  userLogout: () => {},
};

export default AppBar;
