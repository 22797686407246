import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 22,
    color: theme.palette.text.darkBlue,
    textAlign: 'center',
    marginTop: 35,
    padding: '0 16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '5vw',
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  textInfo: {
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    marginTop: 40,
    marginBottom: 10,
  },
  image: {
    width: 150,
    marginTop: 25,
  },
  btnContainer: {
    marginTop: 10,
    textAlign: 'center',
  },
  button: {
    width: 200,
    marginTop: 20,
  },
}));
