import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  routes,
  quizPaths,
  quotePaths,
  quoteApprovedPaths,
  quoteUnderAnalysisPaths,
  createProposalPaths,
  proposalPaths,
  proposalUnderAnalysisPaths,
  policyPaths,
  insuredUnauthorizedPaths,
  policyHolderUnauthorizedPaths,
} from 'src/shared/constants';
import Login from 'src/features/auth/views/Login';
import CallbackPage from 'src/features/auth/views/CallbackPage';
import SignoutCallbackPage from 'src/features/auth/views/SignoutCallbackPage';
import LandingPage from 'src/features/landingPage/views/LandingPage';
import QuoteQuizPage from 'src/features/quiz/views/QuoteQuizPage';
import QuoteApproved from 'src/features/quote/views/QuoteApproved';
import QuoteUnderAnalysis from 'src/features/quote/views/QuoteUnderAnalysis';
import QuotePage from 'src/features/quote/views/QuotePage';
import ProposalQuizPage from 'src/features/quiz/views/ProposalQuizPage';
import ProposalUnderAnalysis from 'src/features/proposal/views/ProposalUnderAnalysis';
import { ErrorPage, SmallFooter } from 'src/shared/components';
import SignupPage from 'src/features/signup/views/SignupPage';
import SignupForm from 'src/features/signup/views/SignupForm';
import SignupConfirm from 'src/features/signup/views/SignupConfirm';
import QuoteRoute from 'src/features/quote/views/QuoteRoute';
import ProposalPage from 'src/features/proposal/views/ProposalPage';
import InsuredUnauthorized from 'src/features/quiz/views/InsuredUnauthorized';
import PolicyIssuedPage from 'src/features/proposal/views/PolicyIssuedPage';
import QuoteReady from './features/hyperlink/views/quote-ready/QuoteReady';
import AlreadyApproved from './features/hyperlink/views/already-approved/AlreadyApproved';
import ExpiredHyperlink from './features/hyperlink/views/expired-hyperlink/ExpiredHyperlink';

const Router = ({ AppBar }) => (
  <Switch>
    <Route exact path={routes.home.path} component={LandingPage} />
    <Route exact path={routes.auth.callback} component={CallbackPage} />
    <Route exact path={routes.auth.signout} component={SignoutCallbackPage} />
    <Route exact path={routes.signup.path}>
      <SignupPage>
        <SignupForm />
      </SignupPage>
    </Route>
    <Route exact path={`${routes.signup.path}/:email`}>
      <SignupPage>
        <SignupConfirm />
      </SignupPage>
    </Route>
    <Route path="*">
      <>
        <section style={{ minHeight: '100%', position: 'relative' }}>
          <div style={{ paddingBottom: 85 }}>
            <AppBar />
            <Switch>
              <Route exact path={routes.hyperlink.quoteReady} component={QuoteReady} />
              <Route exact path={routes.hyperlink.alreadyApproved} component={AlreadyApproved} />
              <Route exact path={routes.hyperlink.invalid} component={ExpiredHyperlink} />
              <Route exact path={quizPaths} component={QuoteQuizPage} />
              <Route exact path={quoteApprovedPaths} component={QuoteApproved} />
              <Route exact path={quoteUnderAnalysisPaths} component={QuoteUnderAnalysis} />
              <Route exact path={routes.quote.path} component={QuoteRoute} />
              <Route
                exact
                path={quotePaths}
                render={() => (
                  <Login>
                    <QuotePage />
                  </Login>
                )}
              />
              <Route
                exact
                path={createProposalPaths}
                render={() => (
                  <Login>
                    <ProposalQuizPage />
                  </Login>
                )}
              />
              <Route
                exact
                path={proposalPaths}
                render={() => (
                  <Login>
                    <ProposalPage />
                  </Login>
                )}
              />
              <Route exact path={proposalUnderAnalysisPaths} component={ProposalUnderAnalysis} />
              <Route
                exact
                path={policyPaths}
                render={() => (
                  <Login>
                    <PolicyIssuedPage />
                  </Login>
                )}
              />

              <Route exact path={insuredUnauthorizedPaths} component={InsuredUnauthorized} />
              <Route exact path={policyHolderUnauthorizedPaths} component={InsuredUnauthorized} />
              <Route exact path={routes.error.path} component={ErrorPage} />
              <Route render={() => <Redirect path="*" to={routes.home.path} />} />
            </Switch>
          </div>
          <SmallFooter />
        </section>
      </>
    </Route>
  </Switch>
);

Router.propTypes = {
  AppBar: PropTypes.func.isRequired,
};

export default Router;
