import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  quoteStatus,
  approved,
  underAnalysis,
  policyHolderUnauthorized,
} from 'src/shared/constants';
import { getValueFromProperties } from 'src/shared/helpers';
import { quizOperations } from '../redux';
import { getProperties, getQuote } from '../redux/quizSelectors';
import useProductKey from './useProductKey';

export default () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const { productKey } = useProductKey();
  const properties = useSelector(getProperties);
  const quote = useSelector(getQuote);
  const pathName = location.pathname;

  const createQuote = () =>
    dispatch(
      quizOperations.createQuote({
        productKey,
        properties: { ...getValueFromProperties(properties) },
      })
    );

  useEffect(() => {
    if (quote.status) {
      if (quote.status === quoteStatus.approved) push(`${pathName}${approved}`);
      else if (quote.status === quoteStatus.unauthorized)
        push(`${pathName}${policyHolderUnauthorized}`);
      else push(`${pathName}${underAnalysis}`);
    }
  }, [quote.status, push, pathName]);

  return { quote, createQuote };
};
