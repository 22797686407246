import { useCallback } from 'react';
import ReactGA from 'react-ga';

const useGoogleAnalytics = () => {
  const event = useCallback((category, action, label = '') => {
    if (!window.env.GOOGLE_KEY) {
      return;
    }

    ReactGA.event({ category, action, label });
  }, []);

  return { event };
};

export default useGoogleAnalytics;
