import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumberFormatText = (props) => {
  const { value } = props;
  return (
    <NumberFormat
      key="num"
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      value={value}
      {...props}
    />
  );
};

NumberFormatText.propTypes = {
  value: PropTypes.number.isRequired,
};

const HelperText = (props) => {
  const { error, min, max, prefix, ...other } = props;

  if (error === 'max') {
    return (
      <NumberFormatText prefix={`O valor deve ser inferior a ${prefix}`} value={max} {...other} />
    );
  }

  if (error === 'min') {
    return (
      <NumberFormatText prefix={`O valor deve ser superior a ${prefix}`} value={min} {...other} />
    );
  }

  return null;
};

HelperText.propTypes = {
  error: PropTypes.oneOf(['min', 'max']).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  prefix: PropTypes.string,
};

HelperText.defaultProps = {
  min: 0,
  max: 0,
  prefix: '',
};

export default HelperText;
