import * as extenso from 'extenso';
import quizService from './quizService';

const getPropertiesWithIS = ({ quote, properties }) => {
  const convertedInsuredAmount = quote.insuredAmount?.toFixed(2).toString().replace('.', ',');
  return {
    ...properties,
    INSURED_AMOUNT_POR_EXTENSO: extenso(convertedInsuredAmount, { mode: 'currency' }),
  };
};

class QuizManager {
  getQuizAndProductInfoByKey = quizService.getQuizAndProductInfoByKey;

  createQuote = quizService.createQuote;

  createProposal = async ({ productKey, quoteId, properties }) => {
    const quote = await quizService.getQuoteById({ quoteId, productKey });
    const propertiesWithIS = getPropertiesWithIS({ quote, properties });
    const response = await quizService.createProposal({
      productKey,
      quoteId,
      properties: { ...propertiesWithIS },
    });
    return response.proposalId;
  };

  verifyInsured = async ({ documentNumber, quoteId, insuredSector }) => {
    const response = await quizService.verifyInsured({ documentNumber, quoteId, insuredSector });
    return { ...response, documentNumber };
  };

  searchBrokerOptions = quizService.searchBrokerOptions;

  verifyBrokerAppointment = quizService.verifyBrokerAppointment;
}

export default new QuizManager();
