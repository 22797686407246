const useUtils = () => {
  function formatMoney(value) {
    if (value !== null) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return 'R$ 0,00';
  }

  function getDateTime(value) {
    const date = new Date(value);
    date.setHours(date.getHours() - 3);
    return date;
  }

  const formatUtcDate = (value) => {
    const date = getDateTime(value);
    return (
      `${(`0${  date.getDate()}`).slice(-2) 
      }/${ 
      (`0${  date.getMonth() + 1}`).slice(-2) 
      }/${ 
      date.getFullYear()}`
    );
  };

  function subtractTimeZone(dateString) {
    const date = new Date(dateString);
    date.setHours(date.getHours() - 3);
    return date.toISOString();
  }

  function getTotalDaysDifference(documentPeriodStart, documentPeriodEnd) {
    const startDate = new Date(subtractTimeZone(documentPeriodStart));
    const endDate = new Date(subtractTimeZone(documentPeriodEnd));

    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

    return Math.floor(differenceInDays);
  }

  return {
    formatMoney,
    getDateTime,
    formatUtcDate,
    subtractTimeZone,
    getTotalDaysDifference,
  };
};

export default useUtils;
