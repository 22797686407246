import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as oidc } from 'redux-oidc';
import { quizReducer } from 'src/features/quiz/redux';
import { loaderReducer } from 'src/features/loader/redux';
import { authReducer } from 'src/features/auth/redux';
import { quoteReducer } from 'src/features/quote/redux';
import { proposalReducer } from 'src/features/proposal/redux';
import { signupReducer } from 'src/features/signup/redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    oidc,
    auth: authReducer,
    quiz: quizReducer,
    quote: quoteReducer,
    proposal: proposalReducer,
    loader: loaderReducer,
    signup: signupReducer,
  });
