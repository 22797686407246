import { routes } from 'src/shared/constants';
import { getProductKeyByPathname } from 'src/shared/helpers';
import { getCustomQuoteValidator } from '../redux/quoteValidators';

export default (push) => (quote, pathname, status) => {
  const productKey = getProductKeyByPathname(pathname);
  const approvedQuoteValidator = getCustomQuoteValidator([productKey], [status]);

  try {
    approvedQuoteValidator.validateSync(quote);
  } catch {
    push(routes.home.path);
  }
};
