import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: 24,
    width: 'calc(100% - 48px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    background: 'white',
    boxShadow: '0 2px 16px hsla(0,0%,80%,.31026)',
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  boxDetailsQuote: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '8px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr ',
    },
  },
  boxValue: {
    display: 'flex',
    gap: 4,
  },
  label: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  value: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginLeft: 4,
    wordBreak: 'break-word',
  },
  subTitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  boxList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '8px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr ',
    },
  },
}));
