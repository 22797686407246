import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { proposalStatus, fetchStatus, routes } from 'src/shared/constants';
import { proposalOperations, proposalActions } from 'src/features/proposal/redux/';
import { getProposal } from 'src/features/proposal/redux/proposalSelector';
import { useProductKey } from 'src/features/quiz/hooks';

export default () => {
  const dispatch = useDispatch();
  const proposal = useSelector(getProposal);
  const { productKey } = useProductKey();

  const policyCanBeIssued = proposal.status === proposalStatus.accepted;
  const hasIssuePolicyError = proposal.fetchStatus.issuePolicy === fetchStatus.rejected;
  const isPolicyIssued = proposal.fetchStatus.issuePolicy === fetchStatus.fulfilled;

  const [isIssuanceConfirmationOpened, setIssuanceConfirmation] = useState(false);
  useEffect(() => {
    if (isIssuanceConfirmationOpened) {
      setIssuanceConfirmation(true);
    }
  }, [setIssuanceConfirmation, isIssuanceConfirmationOpened]);
  const closeIssuanceConfirmation = () => setIssuanceConfirmation(false);
  const openIssuanceConfirmation = () => setIssuanceConfirmation(true);

  const issuePolicy = async () => {
    setIssuanceConfirmation(false);
    dispatch(
      proposalOperations.issuePolicy({
        productKey: proposal.productKey,
        proposalId: proposal.proposalId,
      })
    );
  };

  const closeIssuePolicyError = () => {
    dispatch(proposalActions.cleanIssuePolicyError());
  };

  const { push } = useHistory();
  const policyPath = `${routes[productKey].path}${routes.proposal.path}/${proposal.proposalId}/apolice`;

  useEffect(() => {
    if (isPolicyIssued) {
      push(policyPath);
    }
  }, [isPolicyIssued, policyPath, push]);

  return {
    policyCanBeIssued,
    issuePolicy,
    isIssuanceConfirmationOpened,
    openIssuanceConfirmation,
    closeIssuanceConfirmation,
    closeIssuePolicyError,
    hasIssuePolicyError,
  };
};
