import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { loadUser } from 'redux-oidc';
import { quizActions } from 'src/features/quiz/redux';
import { authManager } from 'src/features/auth/authManager';
import createRootReducer from './reducers';
import { storeQuote, setIsAxiosAuthorized } from './middlewares';
import loadQuote from './loadQuote';

const history = createBrowserHistory();

export default function configureAppStore() {
  const store = configureStore({
    reducer: createRootReducer(history),
    middleware: [routerMiddleware(history), thunkMiddleware, storeQuote, setIsAxiosAuthorized],
    preloadedState: loadQuote(),
  });
  loadUser(store, authManager.getUserManager());
  try {
    const quoteId = localStorage.getItem('quoteId');
    if (quoteId) store.dispatch(quizActions.setQuoteId(quoteId));
  } catch {}
  return store;
}

export { history };
